// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { returnTruthyString } from "../../../components/src/HelperUtils";
import { IEmployee } from "../../../components/src/interfaces.web";

interface IDeleteEmpResponse {
  message: string
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  apiToken: string,
  establishmentId: string,
  // MODALS
  openAddEmployeeModal: boolean,
  openDeleteEmployeeModal: boolean,
  // OTHER
  anchorElementAction: HTMLElement | null;
  employeeActionId: string;
  // API
  employeeListLoading: boolean;
  employeeList: IEmployee[];
  deleteEmployeeLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmployeeManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEmployeeListApiCallId: string = ""
  deleteEmployeeApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      apiToken: returnTruthyString(localStorage.getItem("login_token")),
      establishmentId: returnTruthyString(localStorage.getItem("establishment_ID")),
      // MODALS
      openAddEmployeeModal: false,
      openDeleteEmployeeModal: false,
      // OTHER
      anchorElementAction: null,
      employeeActionId: "",
      // API
      employeeListLoading: false,
      employeeList: [],
      deleteEmployeeLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId) {
        if (apiRequestCallId == this.getEmployeeListApiCallId) {
          return this.getEmployeeListApiResp(responseJson)
        }
        if (apiRequestCallId == this.deleteEmployeeApiCallId) {
          return this.deleteEmployeeApiResp(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getEmployeeListApiResp = (responseJson: IEmployee[]) => {
    this.setState({ employeeListLoading: false })
    if (responseJson && responseJson.length) {
      this.setState({
        employeeList: responseJson
      })
    } else {
      this.setState({
        employeeList: []
      })
    }
  }
  deleteEmployeeApiResp = (responseJson: IDeleteEmpResponse) => {
    this.setState({ deleteEmployeeLoading: false })
    if (responseJson?.message && responseJson.message.includes(configJSON.DEFAULT_MSG.DELETED_SUCCESSFULLY)) {
      this.handleCloseDeleteModal()
      this.handleCloseActionMenu()
      this.getEmployees()
    }
  }

  async componentDidMount() {
    this.getEmployees()
  }

  getEmployees = () => {
    const { establishmentId } = this.state
    if (!establishmentId) return
    this.setState({ employeeListLoading: true, employeeList: [] })

    const header = {
      "token": this.state.apiToken
    };
    const endpoint = `${configJSON.ENDPOINTS.GET_EMPLOYEE_LIST}?establishment_id=${establishmentId}`

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEmployeeListApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  deleteEmployee = (employeeId: string) => {
    const { establishmentId } = this.state
    if (!employeeId || !establishmentId) return
    this.setState({ deleteEmployeeLoading: true })

    const header = {
      "token": this.state.apiToken
    };
    const endpoint = `${configJSON.ENDPOINTS.DELETE_EMPLOYEE}/${employeeId}?establishment_id=${establishmentId}`

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteEmployeeApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.DELETE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  handleOpenAddEmployee = () => {
    this.setState({ openAddEmployeeModal: true });
  };
  handleCloseAddEmployee = () => {
    this.setState({ openAddEmployeeModal: false });
  };

  handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>, id: string) => {
    this.setState({
      anchorElementAction: event.currentTarget,
      employeeActionId: id,
    });
  };

  handleCloseActionMenu = () => {
    this.setState({
      anchorElementAction: null,
      employeeActionId: "",
    });
  };

  handleOpenDeleteModal = () => {
    this.setState({ openDeleteEmployeeModal: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteEmployeeModal: false });
  };

  handleConfirmDelete = () => {
    const { employeeActionId } = this.state
    this.deleteEmployee(employeeActionId)
  }
  // Customizable Area End
}