import { enCustomerSignUp, ptCustomerSignUp } from "./translations/customerSignUp";
import { enForgotNewPassword, ptForgotNewPassword } from "./translations/forgotNewPassword";
import { enLogin, ptLogin } from "./translations/login";
import { enVendorSignUp, ptVendorSignUp } from "./translations/vendorSignUp";

export const availableResources = {
    en: {
        login: enLogin,
        customerSignUp: enCustomerSignUp,
        forgotNewPassword:enForgotNewPassword,
        vendorSignUp: enVendorSignUp
    },
    pt: {
        login: ptLogin,
        customerSignUp: ptCustomerSignUp,
        forgotNewPassword: ptForgotNewPassword,
        vendorSignUp: ptVendorSignUp
    }
}
