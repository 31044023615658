Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loggedInSessionEndPoint = "account_block/logged_sessions";
exports.googleMapAPIKey = "AIzaSyBofbgX0OHrNfmaEKb_OQvWEiTxS-11KiI";
exports.geolocationUrl = "https://www.googleapis.com/geolocation/v1/geolocate?key=";
exports.geocodingUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=";
exports.userLoginEndPoint = "bx_block_login/logins";

// Customizable Area End