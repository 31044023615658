export const enForgotNewPassword = {
    "Welcome back!":"Welcome back!",
    "You don't have an account yet?":"You don't have an account yet?",
    "Register":"Register",
    "here":"here",
    //FIELD
    "Email":"Email",
    "Password":"Password",
    "Confirm Password":"Confirm Password",
    //PLACEHOLDER
    "Your Email":"Your Email",
    //ERROR
    "*Please enter your email to proceed.":"*Please enter your email to proceed.",
    "Invalid email address":"Invalid email address",
    "*Please enter password.":"*Please enter password.",
    "*Please enter confirm password.":"*Please enter confirm password.",
    "Password should include at least 8 characters, one number, and one letter.":"Password should include at least 8 characters, one number, and one letter.",
    "*Passwords does not match.":"*Passwords does not match.",
    //BUTTON
    "Send Email":"Send Email",
    "Save":"Save",
    //MESSAGE
    "Forget Password":"Forget Password",
    "Please verify your email":"Please verify your email",
    "We have sent the verification link to":"We have sent the verification link to",
    "Resend Email":"Resend Email",
    "Password Successfully changed.":"Password Successfully changed.",
    "Proceed to login":"Proceed to login"
}

export const ptForgotNewPassword = {
    "Welcome back!":"Bem vindo de volta!",
    "You don't have an account yet?":"Você ainda não tem uma conta?",
    "Register":"Cadastre-se",
    "here":"aqui",
    //FIELD
    "Email":"E-mail",
    "Password":"Senha",
    "Confirm Password":"Confirme sua senha",
    //PLACEHOLDER
    "Your Email":"Seu e-mail",
    //ERROR
    "*Please enter your email to proceed.":"*Insira seu e-mail para prosseguir.",
    "Invalid email address":"Endereço de e-mail inválido",
    "*Please enter password.":"*Por favor insira a senha.",
    "*Please enter confirm password.":"*Por favor insira a senha de confirmação.",
    "Password should include at least 8 characters, one number, and one letter.":"A senha deve incluir pelo menos 8 caracteres, um número e uma letra.",
    "*Passwords does not match.":"*As senhas não coincidem.",
    //BUTTON
    "Send Email":"Enviar e-mail",
    "Save":"Salvar",
    //MESSAGE
    "Forget Password":"Esqueci a senha",
    "Please verify your email":"Verifique seu e-mail",
    "We have sent the verification link to":"Enviamos o link de verificação para",
    "Resend Email":"Reenviar e-mail",
    "Password Successfully changed.":"Senha alterada com sucesso.",
    "Proceed to login":"Prossiga para fazer login"
}