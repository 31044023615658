import React, { useRef, useState } from 'react';
import { IPet, configJSON } from '../CustomisableUserProfilesController';
import { Avatar, Backdrop, Box, CircularProgress, ClickAwayListener, Fade, IconButton, Modal, Paper, Popper, Typography, styled, useMediaQuery } from '@material-ui/core';
import { UploadSvg } from '../assets';
import PetName from './PetName';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';


type Props = {
    selectedPet: IPet;
    onUpdatePet: (pet: IPet, petPhoto?: File) => void;
    onUpdateField: (field: keyof IPet, value: IPet[keyof IPet]) => void;
    onRemovePhoto: (petPhoto?: File) => void;
    isLoadingPhoto: boolean;
}

const useStyles = makeStyles((theme) => ({
    image: {
      width: "8rem",
      height: "8rem",
      objectFit: "cover",
      borderRadius: "50%",
    },
    largeImage: {
        width: "100%",
        maxWidth: "30rem",
        height: "auto",
        objectFit: "cover",
        borderRadius: theme.shape.borderRadius,
      },
    modalContent: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      outline: "none",
      borderRadius: theme.shape.borderRadius,
      width: "90%", 
      maxWidth: "500px", 
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closeButton: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[200],
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          backgroundColor: theme.palette.grey[300],
        },
      },
    closeIcon: {
        fontSize: "1.25rem",
      },
      
  }));

const Wrapper = styled(Box)({
    display: 'flex', 
    flexDirection: 'column', 
    gap: '32px',

    '& .header-text': {
        fontSize: '40px', fontWeight: 600
    },
    '& .header-items': {
        display: 'flex', 
        gap: '28px', 
        alignItems: 'center'
    },
    '& .avatar': {
        width: '148px', height: '148px'
    },
    '& .upload-photo': {
        width: '148px', 
        height: '148px', 
        borderRadius: '50%', 
        background: '#F0E5FF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    '& .header-info-wrapper': {
        display: 'flex', 
        flexDirection: 'column', 
        gap: '12px', 
        textAlign: 'left',
        width: '100%'
    },
    '& .header-info-label': {
        color: '#A6A6A6', 
        fontWeight: 300, 
        fontSize: '13px'
    },
    '& .header-info-name': {
        fontWeight: 600, fontSize: '30px'
    },
    '& .paper-item': {
        padding: '16px',
        fontSize: '16px',
        fontWeight: 400,
        cursor: 'pointer'
    }
})

const PetInfoHeader = ({ selectedPet, isLoadingPhoto, onUpdatePet, onUpdateField, onRemovePhoto }: Props) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const popperRef = useRef<HTMLDivElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if(file) {
            if(selectedPet.id) {
                onUpdatePet({ ...selectedPet, photo_blob: file })
            } else {
                onUpdateField('photo_blob', file);
            }
        }
      };

    const handleNameChange = (name: string) => {
        if(selectedPet.id) {
            onUpdatePet({...selectedPet, name });
        } else {
            onUpdateField('name', name);
        }
    }

    const handleOpenFileSelect = () => {
        if(fileInputRef.current) {
            fileInputRef.current.click();
        }
    }
    const handleRemovePetPhoto = () => {
        const photo = "https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png"
        onRemovePhoto();
        setOpen(false);
        setTimeout(()=>{
            onUpdatePet({...selectedPet, pet_photo: photo, photo_blob: undefined });
        },2000)
    }

    return (
        <Wrapper>
            <input
                data-test-id="file-select"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            <Typography className='header-text'>{configJSON.myPets}</Typography>
            <Box className='header-items'>
                <div ref={anchorRef} onClick={() => setOpen((prev) => !prev)} data-test-id='text-section'>
                    {(selectedPet.pet_photo || selectedPet.photo_blob)
                        ? <Avatar style={{cursor:"cursor"}} className='avatar'>
                            {isLoadingPhoto && 
                            <CircularProgress
                            size={40}
                            style={{
                                position: 'absolute',
                                zIndex: 1,
                            }}
                            />}                           
                            <img
                            src={selectedPet.pet_photo || URL.createObjectURL(selectedPet.photo_blob as Blob) || undefined}
                            alt="avatar"
                            style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: "cover", cursor: 'pointer' }}
                            />                   
                        </Avatar>
                        : <Box style={{cursor:"cursor"}} className='upload-photo'>
                             <img src={UploadSvg} alt="Upload" />   
                        </Box>
                    }
                </div>
                <Popper
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorRef.current}
                    placement="right"
                    ref={popperRef}
                >
                    <ClickAwayListener onClickAway={() => setOpen(false)} data-test-id='clickaway'>
                        <Paper style={{ padding: '16px'}}>
                            {selectedPet.pet_photo && selectedPet.pet_photo !== "https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png" ?
                             <Box onClick={openModal} data-test-id="view-select-photo" style={{marginBottom: "32px"}}>
                             <Typography style={{cursor: "pointer"}} className='paper-item'>View photo</Typography> 
                            </Box> : ""}
                            <Box onClick={handleOpenFileSelect} data-test-id="file-select-visible">
                                <Typography style={{cursor: "pointer"}} className='paper-item'>{selectedPet.pet_photo === "https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png" || selectedPet.pet_photo === null ? 'Choose' : 'Change'} photo</Typography>
                            </Box>
                            {selectedPet.pet_photo && selectedPet.pet_photo !== "https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png" ?
                             <Box style={{marginTop: "32px"}} onClick={handleRemovePetPhoto} data-test-id="remove-select-photo">
                             <Typography style={{cursor: "pointer"}} className='paper-item'>Remove photo</Typography>
                            </Box> : ""}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
                <Box className='header-info-wrapper'>
                    <Typography className='header-info-label'>{configJSON.name}</Typography>
                    <PetName name={selectedPet.name} onSaveName={handleNameChange} isNew={!selectedPet.id} />
                </Box>
            </Box>
                    <Modal
                open={isModalOpen}
                onClose={closeModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={isModalOpen}>
                <div className={classes.modalContent} style={{ maxWidth: isSmallScreen ? "90%" : "500px" }}>
                    <IconButton className={classes.closeButton} onClick={closeModal}>
                        <CancelOutlinedIcon className={classes.closeIcon}/>
                    </IconButton>
                    <img src={selectedPet.pet_photo || ""} alt="Enlarged Profile" className={classes.largeImage} style={{ width: isSmallScreen ? "100%" : "30rem" }} />
                </div>
                </Fade>
            </Modal>
        </Wrapper>
    )
}

export default PetInfoHeader;