import { object } from "yup";
import { Request } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";

export const getLatestRequest = (requests: Request[] | null): Request | null => {
    if(requests && requests.length > 0) {
        const latestRequest = requests.reduce((max, obj) => (obj.id > max.id ? obj : max), requests[0])
        return latestRequest;
    } else {
        return null
    }
}