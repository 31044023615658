import { Box, Dialog, DialogContent, IconButton, Typography, styled } from '@material-ui/core';
import React, { ReactNode } from 'react';
export const closeIcon =  require("../src/crosssvg.svg");
interface ModalProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
  className?:string;
  closeIconProp?:string;
}

const CustomDialog: React.FC<ModalProps> = ({ open, onClose, title, children, className, closeIconProp }) => {
  return (
    <DialogContainer PaperProps={{style : {borderRadius: "32px", boxShadow: "none"}}} onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <Box id="customized-dialog-title" >
        <Box display="flex" justifyContent="space-between" alignItems="center" >
          <Typography variant="h6" className={`title ${className}`}>{title}</Typography>
          <IconButton style={{position: "absolute", right: "22px"}} onClick={onClose} aria-label="close" size="small" >
            <img className={`close-icon ${className}`} src={closeIcon} alt='close-icon'/>
          </IconButton>
        </Box>
        </Box>
        <DialogContent>
        {children}
        </DialogContent>
      </DialogContainer>
  );
};

export default CustomDialog;

const DialogContainer = styled(Dialog)(({ theme }) => ({
  
  "& .MuiDialog-paper":{
    padding:"10px 20px",
    borderRadius:20
  },
  "& .MuiDialogContent-dividers":{
    borderTop:"none",
    borderBottom:"none"
  },
  "& .title":{
    padding:"10px 20px 10px 20px"
  },
  "& .close-icon":{
    height:"13px",
    width:"13px",
    [theme.breakpoints.down("xs")]: {
      width:"15px",
    },
  }
}))