import React, { ChangeEvent } from "react";
// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
}
interface IImage {
    id: number;
    url: string
}
interface IUploadImage {
    id: null | number;
    url: string;
    file?: File;
}

interface IUploadResponse {
    galleries: IImage[],
    meta: {
        message: string
    }
}

interface IDeleteResponse {
    meta: {
        message: string
    },
    error?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    apiToken: string;
    establishmentId: string;
    uploadedFiles: IUploadImage[];
    isEditImage: boolean;
    serviceImageListResponse: IImage[];
    deleteImageIds: number[]
    //LOADER
    isSaveLoading: boolean;
    isGetListLoader: boolean;
    uploadGalleryLoader: boolean;
    deleteGalleryLoader: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class EstablishmentSettingGalleryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getGalleryApiCallId: string = "";
    updateGalleryApiCallId: string = "";
    deleteGalleryApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            apiToken: localStorage.getItem("login_token") || "",
            establishmentId: localStorage.getItem("establishment_ID") || "",
            uploadedFiles: [],
            isEditImage: false,
            serviceImageListResponse: [],
            deleteImageIds: [],
            //LOADER
            isSaveLoading: false,
            isGetListLoader: false,
            uploadGalleryLoader: false,
            deleteGalleryLoader: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId && responseJson) {
            switch (apiRequestCallId) {
                case this.getGalleryApiCallId:
                    this.getGalleryApiResp(responseJson);
                    break;
                case this.updateGalleryApiCallId:
                    this.updateGalleryApiResp(responseJson);
                    break;
                case this.deleteGalleryApiCallId:
                    this.deleteGalleryApiResp(responseJson);
                    break;
                default:
                    break;
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getGalleryApiResp = (responseJSON: IUploadResponse) => {
        this.setState({ isGetListLoader: false })
        if (responseJSON.galleries && responseJSON.galleries.length) {
            this.setState({ serviceImageListResponse: responseJSON.galleries, uploadedFiles: responseJSON.galleries })
        }
    }

    updateGalleryApiResp = (responseJson: IUploadResponse) => {
        this.setState({ uploadGalleryLoader: false });
        this.changeSaveLoadingStatus();
        if (responseJson && responseJson.galleries.length > 0) {
            this.setState({ uploadedFiles: [], serviceImageListResponse: [] })
        }
    }

    deleteGalleryApiResp = (responseJson: IDeleteResponse) => {
        this.setState({ deleteGalleryLoader: false });
        this.changeSaveLoadingStatus();
        if (responseJson && responseJson.meta.message) {
            this.setState({ deleteImageIds: [] })
        }
    }

    async componentDidMount(): Promise<void> {
        this.getGalleryAPI();
    }


    apiCall = async (data: IApiModel) => {
        const { contentType, method, endPoint, body, token, isJsonStringify } =
            data;
        let header;
        header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                isJsonStringify ? JSON.stringify(body) : body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getGalleryAPI = async () => {
        this.setState({ isGetListLoader: true, serviceImageListResponse: [] })
        const { establishmentId, apiToken } = this.state

        const endpoint = `${configJSON.ENDPOINTS.GET_GALLERY}${establishmentId}`
        if (establishmentId) {
            this.getGalleryApiCallId = await this.apiCall({
                contentType: configJSON.API_CONTENT_TYPE.APPLICATION_JSON,
                method: configJSON.API_METHOD.GET,
                endPoint: endpoint,
                token: apiToken,
            });
        }
    };

    uploadImageAPI = async (newImageList: IUploadImage[]) => {
        const { establishmentId, apiToken } = this.state

        let imagesFormData = new FormData();
        if (newImageList.length > 0) {
            newImageList.forEach((file) => {
                if (file.file) {
                    imagesFormData.append("establishment[galleries][]", file.file);
                }
            });
        }
        this.setState({ uploadGalleryLoader: true });
        if (establishmentId) {
            this.updateGalleryApiCallId = await this.apiCall({
                method: configJSON.API_METHOD.PUT,
                endPoint: `${configJSON.ENDPOINTS.UPLOAD_GALLERY}${establishmentId}`,
                body: imagesFormData,
                token: apiToken,
            });
        }
    }

    deleteImageAPI = async () => {
        const { establishmentId, apiToken } = this.state
        this.setState({ deleteGalleryLoader: true });

        if (establishmentId) {
            this.deleteGalleryApiCallId = await this.apiCall({
                method: configJSON.API_METHOD.DELETE,
                endPoint: `${configJSON.ENDPOINTS.DELETE_GALLERY}?id=${establishmentId}&gallery_ids[]=${this.state.deleteImageIds}`,
                token: apiToken,
            });
        }
    }

    handleMediaSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files) {
            const mediaFiles: IUploadImage[] = Array.from(files).map((file) => ({
                id: null,
                url: URL.createObjectURL(file),
                file: file
            }));

            this.setState((prevState) => ({
                uploadedFiles: [...prevState.uploadedFiles, ...mediaFiles],
                isEditImage: true
            }));
        }
    };

    handleEditClick = () => {
        this.setState({ isEditImage: true })
    }

    handleCancelClick = () => {
        this.setState({ isEditImage: false, uploadedFiles: this.state.serviceImageListResponse, deleteImageIds: [] })
    }

    handleRemoveFile = (fileToRemove: File, imageId: number | null) => {
        if (imageId !== null) {
            const fileToDelete = this.state.uploadedFiles.filter((mediaFile) => mediaFile.id !== imageId)
            this.setState((prevState) => ({
                deleteImageIds: [...prevState.deleteImageIds, imageId],
                uploadedFiles: fileToDelete,
            }));
        } else {
            this.state.uploadedFiles.forEach((mediaFile) => {
                if (mediaFile.file === fileToRemove) {
                    URL.revokeObjectURL(mediaFile.url);
                }
            });
            this.setState((prevState) => ({
                uploadedFiles: prevState.uploadedFiles.filter((mediaFile) => mediaFile.file !== fileToRemove),
            }));
        }
    };


    handleSaveClick = () => {
        const { uploadedFiles, deleteImageIds } = this.state;
        const newImageList = uploadedFiles.filter((mediaFile) => mediaFile.id === null);
        if ((deleteImageIds.length > 0 || newImageList.length > 0)) {
            this.setState({ isSaveLoading: true });
        }
        if (newImageList.length > 0) {
            this.uploadImageAPI(newImageList);
        }
        if (deleteImageIds.length > 0) {
            this.deleteImageAPI();
        }
    }

    changeSaveLoadingStatus = () => {
        const { uploadGalleryLoader, deleteGalleryLoader } = this.state;
        if (!uploadGalleryLoader && !deleteGalleryLoader) {
            this.setState({ isSaveLoading: false, isEditImage: false });
            this.getGalleryAPI();
        }
    };
    // Customizable Area End
}