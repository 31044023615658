import React from "react";
// Customizable Area Start
import {
  Box, styled, Button, Typography, TextField, FormControlLabel, Checkbox, CircularProgress,
  IconButton,
  Dialog
} from "@material-ui/core";
import { infoIcon, offSwitchIcon, onSwitchIcon, ViewClose } from "../assets";
import PricesVerticalController, { Props, webConfigJSON } from "./PricesVerticalController.web";
import { checkCondition, returnTruthyString } from "../../../../components/src/HelperUtils";
type TWeekKeys = "Once a week" | "Twice a week" | "3 times a week" | "4 times a week" | "5 times a week" | "6 times a week" | "7 times a week"

const LeftMenus: TWeekKeys[] = [
  "Once a week",
  "Twice a week",
  "3 times a week",
  "4 times a week",
  "5 times a week",
  "6 times a week",
  "7 times a week"
]
// Customizable Area End

export default class PricesVertical extends PricesVerticalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderSwitches = () => {
    return (
      <>
        <Box style={style.switchWrapper}>
          {this.transportationSwitch()}
          {this.medicationSwitch()}
        </Box>

      </>
    )
  }
  transportationSwitch = () => {
    const { transportation } = this.state
    const notAllowedServices = [webConfigJSON.SERVICES_ENUM.PET_SITTING, webConfigJSON.SERVICES_ENUM.DOG_WALKING]
    const isShowTransportation = !notAllowedServices.includes(this.props.selectedService.name)
    return (
      <>
        {isShowTransportation ?
          <RowCheckBox>
            <LeftCheckBox>
              <CustomCheckbox
                disabled={!this.state.isEditOn}
                value={transportation.checked}
                checked={transportation.checked}
                control={
                  <Checkbox
                    name="transportation"
                    onChange={(event) => this.setSwitchCheck(event.target.checked, "transportation")}
                    value={transportation.checked}
                    className="transportation-switch"
                    disableRipple
                    checkedIcon={<img src={onSwitchIcon} />}
                    icon={<img src={offSwitchIcon} />}
                  />
                }
                label="Transportation"
                labelPlacement="start"
                data-testid="transportation-switch"
              />
            </LeftCheckBox>
            {transportation.checked ?
              <RightCheckBox>
                <CustomInput
                  inputProps={{ min: 0 }}
                  type="number"
                  id={"transportation-input"}
                  disabled={!this.state.isEditOn}
                  value={transportation.price}
                  onChange={(event) => this.setSwitchPrice(event.target.value, "transportation")}
                  error={this.getError("transportation", transportation.sub_service_id, true).error}
                  helperText={this.getError("transportation", transportation.sub_service_id, true).errorMsg}
                />
              </RightCheckBox>
              : <></>}
          </RowCheckBox> : <></>
        }
      </>
    )
  }
  medicationSwitch = () => {
    const { medication } = this.state
    const notAllowedServices = [webConfigJSON.SERVICES_ENUM.GROOMING]
    const isShowMedication = !notAllowedServices.includes(this.props.selectedService.name)
    return (
      <>
        {isShowMedication ?
          <RowCheckBox>
            <LeftCheckBox>
              <CustomCheckbox
                disabled={!this.state.isEditOn}
                value={medication.checked}
                checked={medication.checked}
                control={
                  <Checkbox
                    name="medication"
                    onChange={(event) => this.setSwitchCheck(event.target.checked, "medication")}
                    value={medication.checked}
                    className="medication-switch"
                    disableRipple
                    checkedIcon={<img src={onSwitchIcon} />}
                    icon={<img src={offSwitchIcon} />}
                  />
                }
                label="Medication"
                labelPlacement="start"
                data-testid="medication-switch"
              />
            </LeftCheckBox>
            {medication.checked ?
              <RightCheckBox>
                <CustomInput
                  inputProps={{ min: 0 }}
                  type="number"
                  id={"medication-input"}
                  disabled={!this.state.isEditOn}
                  value={medication.price}
                  onChange={(event) => this.setSwitchPrice(event.target.value, "medication")}
                  error={this.getError("medication", medication.sub_service_id, true).error}
                  helperText={this.getError("medication", medication.sub_service_id, true).errorMsg}
                />
              </RightCheckBox>
              : <></>}

          </RowCheckBox>
          : <></>
        }
      </>
    )
  }
  renderButtons = () => {
    return (
      <PriceEnd>
        <Box style={style.allPriceBox}>
          <img src={infoIcon} />
          <Typography style={style.allPrice}>All prices are per day.</Typography>
        </Box>
        <ButtonWrapper>
          {this.state.isEditOn ?
            <>
              <CustomButton
                style={style.blackBtn}
                onClick={this.handleCancelClick}
                data-testid="cancel-btn"
              >
                Cancel
              </CustomButton>
              <CustomButton
                style={style.blueBtn}
                onClick={this.handleSaveClick}
                data-testid="save-btn"
              >
                Save
              </CustomButton>
            </>
            :
            <CustomButton
              style={style.blueBtn}
              onClick={this.handleEditClick}
              data-testid="edit-price-btn"
            >
              Edit
            </CustomButton>
          }
        </ButtonWrapper>
      </PriceEnd>
    )
  }
  renderLoader = () => {
    return (
      <Box style={style.noDataLoader}>
        <CircularProgress />
      </Box>
    )
  }
  renderNodataMsg = () => {
    return (
      <Box style={style.noDataLoader} >
        <Typography style={style.noDataText}>No results found!</Typography>
      </Box>
    )
  }
  renderPrices = () => {
    const { subServicesForm } = this.state
    return (
      <>
        {subServicesForm.map((subService, itemIndex) => {
          const service_title = returnTruthyString(subService.sub_service_title)
          return (
            <ColumWrapper key={itemIndex}>
              <span title={service_title}>
                <ColumTitle noWrap>{service_title}</ColumTitle>
              </span>
              <ColumSubTitle>Price in €</ColumSubTitle>
              <InputWrapper>
                {subService.price_data.map((price_data, index) => {
                  return (
                    <CustomInput
                      inputProps={{ min: 0 }}
                      key={`key-${itemIndex}-${index}`}
                      type="number"
                      disabled={!this.state.isEditOn}
                      data-testid={`input-${itemIndex}-${index}`}
                      value={price_data.price}
                      onChange={(event) =>
                        this.setPriceValue(event.target.value, price_data.title, subService.sub_service_id)
                      }
                      error={this.getError(price_data.title, price_data.sub_service_id, false).error}
                      helperText={this.getError(price_data.title, price_data.sub_service_id, false).errorMsg}
                    />
                  )
                })}
              </InputWrapper>
            </ColumWrapper>
          )
        })}
      </>
    )
  }
  renderRighPart = () => {
    const { getPriceDetailsLoading, subServicesForm } = this.state
    return (
      <>
        {getPriceDetailsLoading && !subServicesForm.length ? this.renderLoader() : <></>}
        {!getPriceDetailsLoading && subServicesForm.length ? this.renderPrices() : <></>}
        {!getPriceDetailsLoading && !subServicesForm.length ? this.renderNodataMsg() : <></>}
      </>
    )
  }

  renderDefaultDialog = () => {
    const { isDialogOpen } = this.state;
    const serviceName = checkCondition(Boolean(this.props.selectedService.name === "Day Care"), "spaces", "services") as string;
    return (
      <>
        {isDialogOpen &&
          <StyledCreateVerticalPrice
            fullWidth
            open={isDialogOpen}
            maxWidth={"md"}
            data-test-id="create-vertical-price-modal"
          >
            <Box className="createRoomContainer">
              <CreateVerticalPriceImgWrapper>
                <CloseIconBtn onClick={() => this.createVerticalPriceModalClose()} data-test-id="create-close-btn"><img src={ViewClose} alt="close-icon" /></CloseIconBtn>
              </CreateVerticalPriceImgWrapper>
              <CreateVerticalPriceInfoWrapper>
                <Typography className="createRoomTypo">
                  {webConfigJSON.PRICE_DEFAULT_MESSAGE.PRICE_MSG}
                </Typography>
                <Typography className="pleasFillTypo">
                  {`Please create the ${serviceName} first!`}
                </Typography>
              </CreateVerticalPriceInfoWrapper>
            </Box>
          </StyledCreateVerticalPrice>
        }
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        <Form>
          <RowBox>
            <LeftBox>
              <ColumTitle>Select Recurrence</ColumTitle>
              <ChipWrapper>
                {LeftMenus.map((chip, index) => {
                  return (
                    <ChipBox key={index}>
                      <ChipTitle>{chip}</ChipTitle>
                    </ChipBox>
                  )
                })}
              </ChipWrapper>
            </LeftBox>
            <RightBox>
              {this.renderRighPart()}
            </RightBox>
          </RowBox>
          {this.renderSwitches()}
          {this.renderButtons()}
        </Form>
        {this.renderDefaultDialog()}
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  width: "100%"
})
const Form = styled('form')({
  marginBottom: 0
})
const ColumTitle = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "22px",
  color: "#6F05EA"
})
const ColumSubTitle = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  color: "#6F05EA",
  marginTop: 8
})
const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "32px",
})
const LeftBox = styled(Box)({
  width: "100%",
  maxWidth: "200px",
  minWidth: "150px"
})
const RightBox = styled(Box)({
  width: "100%",
  overflowX: "auto",
  display: "flex",
  flexDirection: "row",
  columnGap: "26px",
  paddingBottom: "48px",
  "@media (max-width: 464px)": {
    marginTop: "20px"
  },
})
const ChipWrapper = styled(Box)({
  marginTop: 32,
  display: "flex",
  flexDirection: "column",
  rowGap: "16px"
})
const ChipBox = styled(Box)({
  padding: "14px 12px",
  borderRadius: "8px",
  boxShadow: "0px 2px 8px 0px #0000000F",
  backgroundColor: "#F8F8F8",
})
const ChipTitle = styled(Box)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#0F172A"
})

const ColumWrapper = styled(Box)({
  width: "176px"
})
const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: 20
})
const ButtonWrapper = styled(Box)({
  padding: "0px",
  display: "flex",
  gap: "12px",
  flexWrap: "wrap",
  justifyContent: "center"
})

const CustomButton = styled(Button)({
  height: "40px",
  width: "140px",
  padding: "8px 0",
  borderRadius: "100px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none",
  fontFamily: 'Montserrat',
})
const CustomInput = styled(TextField)({
  height: "47px",
  marginBottom: "13px",
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiFormHelperText-root": {
    fontFamily: 'Montserrat',
  },
  "& .MuiInputBase-input": {
    padding: "8px !important",
    fontFamily: 'Montserrat',
    minWidth: "176px",
    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontSize: "12px",
      fontWeight: 400,
    },
  },
})
const CustomCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "& .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: 'Montserrat',
  },
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .Mui-checked.medication-switch": {
    marginLeft: "52px !important"
  },
  "& .medication-switch": {
    marginLeft: "46px !important"
  },
  "& .Mui-checked.transportation-switch": {
    marginLeft: "26px !important"
  },
  "& .transportation-switch": {
    marginLeft: "20px !important"
  },

})
const RowCheckBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "48px",
  rowGap: "16px",
  flexWrap: "wrap"
})
const LeftCheckBox = styled(Box)({
  width: "100%",
  maxWidth: "183px"
})
const RightCheckBox = styled(Box)({
  width: "100%",
  maxWidth: "176px"
})
const PriceEnd = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  gap: "16px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
})
const style = {
  blueBtn: {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  } as React.CSSProperties,
  blackBtn: {
    color: "#78716C",
    border: "1px solid #78716C",
  } as React.CSSProperties,
  blueColor: {
    color: "#6F05EA",
  } as React.CSSProperties,
  switchWrapper: {
    marginTop: 44,
    display: "flex",
    rowGap: "16px",
    flexDirection: "column",
  } as React.CSSProperties,
  allPriceBox: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center"
  } as React.CSSProperties,
  allPrice: {
    fontFamily: 'Montserrat',
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#F87171"
  } as React.CSSProperties,
  noDataLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  } as React.CSSProperties,
  noDataText: {
    fontFamily: 'Montserrat',
    color: "rgb(183, 183, 183)",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 18
  } as React.CSSProperties,
}
const StyledCreateVerticalPrice = styled(Dialog)({
  "& .MuiDialog-paperWidthMd": {
    maxWidth: "751px"
  },
  "& .createRoomContainer": {
    padding: "33px"
  }
});
const CreateVerticalPriceImgWrapper = styled(Box)({
  justifyContent: "flex-end",
  display: "flex",
});
const CloseIconBtn = styled(IconButton)({
  width: "32px",
  height: "32px",
});
const CreateVerticalPriceInfoWrapper = styled(Box)({
  textAlign: "center",
  maxWidth: "547px",
  padding: "15px 70px 60px 70px",
  "& .pleasFillTypo": {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "29px",
    "@media (max-width: 960px)": {
      fontSize: "16px",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
    },
  },
  "@media (max-width: 960px)": {
    padding: "10px 50px 50px 70px"
  },
  "@media (max-width: 600px)": {
    padding: "8px 0px 40px 0px"
  },
  "& .createRoomTypo": {
    fontWeight: 600,
    lineHeight: "34px",
    fontSize: "24px",
    "@media (max-width: 960px)": {
      fontSize: "20px",
    },
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
})
// Customizable Area End