import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CommonSelect from "../../../components/src/SelectField";
import * as Yup from "yup";
import {
  cityIcon,
  countryIcon,
  emailIcon,
  forwardArrowIcon,
  nameIcon,
  passwordIcon,
  signUpImage,
  greyDogIcon,
  greyCatIcon,
  greyOtherIcon,
  dogIcon,
  catIcon,
  otherIcon,
  greyRabbitIcon,
  greyReptileIcon,
  greyBirdIcon,
  greyFishIcon,
  rabbitIcon,
  birdIcon,
  fishIcon,
  reptileIcon,
  petNameIcon,
  genderIcon,
  sizeIcon,
  weightIcon,
  FurIcon,
} from "./assets";
import InputField from "../../../components/src/InputField";
import { Formik } from "formik";
import CommonBackdrop from "../../../components/src/BackDrop";
import CommonDatePicker from "../../../components/src/Calendar.web";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import { AppHeader } from "../../../components/src/AppHeader.web";
import TermsConditions from "../../../../packages/blocks/termsconditions/src/TermsConditions.web"
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
const configJSON = require("./config");import { LoadingButton } from "../../../components/src/HelperComponents";
import { checkCondition } from "../../../components/src/HelperUtils";

const webStyles = {
  pageWrapper: {
    display: "flex",
    alignItems: "center",
  },
  commonTabStyle: { width: "227px", textTransform: "none", fontSize: "24px" },
  commonTextFieldStyle: { 
  }
};

const subPetIconMap = {
  bird: birdIcon,
  fish: fishIcon,
  rabbit: rabbitIcon,
  reptile: reptileIcon,
};

const PetGridContainer = styled(Box) (({ theme }) => ({
  border: "1px solid",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "26px 52px 18px 52px",
  cursor:"pointer",
  "& .pet-image": {
    height: "59px",
    width: "75px",
  },
  "& .rabbit-typo": {
    textAlign:"center",
    width: "70px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.between(960,1280)]: { 
    padding:"16px 32px 8px 32px"
  },
}));
const ImageCardGrid = styled(Grid) (({ theme }) => ({
  marginBottom: "41px", 
  flexWrap:"nowrap",
  [theme.breakpoints.down("xs")]: { 
    flexWrap:"wrap",
    flexDirection:"column"
  }
}));
const OtherOptionContainer = styled(Grid) (({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: "25px 28px 22px 28px",
  "& .option-box":{
    display: "flex",
    margin: "auto",
    width: "342px",
    height: "289px",
    gap: "16px",
    [theme.breakpoints.down("xs")]: { 
      width: "272px",
      height: "199px",
    }
  },
  [theme.breakpoints.down("xs")]: { 
    padding: "7px 8px 5px 8px",
  }
}));
const PetOptionBackDropContainer = styled(Box) (({ theme }) =>({
  border: "1px solid",
  color: "#cccccc",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "163px",
  height: "134px",
  cursor:"pointer",
  justifyContent: "center",
  "& .pet-image": {
    height: "59px",
    width: "75px",
  },
  [theme.breakpoints.down("xs")]: { 
    width: "126px",
    height: "94px",
  }
}));

const SubmitButton = styled(LoadingButton)({
  border: "1px solid #6F05EA",
  borderRadius: "100px",
  padding: "10px 16px",
  height: "53px",
  fontSize: "24px",
  gap: "10px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "& .MuiButton-endIcon":{
    marginLeft:"32px",
    width:"36px"
  },
  '@media (min-width:1200px) and (max-width:1600px)': {
    fontSize:"18px !important",
    },
});

const BackButton = styled(Button)({
  border: "1px solid #6F05EA",
  borderRadius: "100px",
  marginRight:"5px",
  height: "53px",
  fontSize: "24px",
  gap: "10px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "& .MuiButton-endIcon":{
    marginLeft:"32px",
    width:"36px"
  },
  '@media (min-width:1200px) and (max-width:1600px)': {
    fontSize:"18px !important",
    },
})

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height:"100%",
  right: "0",
  top: "0",
  "& .Image": {
    width: "100%",
    height:"100%",
  },
  [theme.breakpoints.down("sm")]: { display: "none" },
}));
const PageTitle = styled(Box)(({ theme }) => ({
  fontSize: "28px",
  fontWeight: 700,
  color: "#6F05EA",
  fontFamily: "Montserrat",
  [theme.breakpoints.between("md","lg")]: { fontSize: "22px", marginTop: "40px"},
  [theme.breakpoints.between("sm","md")]: { fontSize: "28px"},
  [theme.breakpoints.down(600)]: { fontSize: "20px"},
  [theme.breakpoints.down(400)]: { fontSize: "18px"},
  '@media (min-width:1200px) and (max-width:1600px)': {
    fontSize:"28px",
    },
}));

const RegisterInfoContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding:"0 24px",
  "& .register-info":{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.between(960,1100)]: { 
      width:"90%",
      padding:"0px 25px"
    }, 
  },
  "& .pet-typography":{
    "& p": {
      textAlign: "center !important"
    }
  },
  [theme.breakpoints.between("md",1050)]: { 
    margin:"15px",
    padding:"0px"
   }, 
   [theme.breakpoints.down("sm")]: { 
    width:"100%",
    margin:"50px auto 0px auto"
   }, 
   [theme.breakpoints.down("xs")]: { 
    width:"80%",
   }, 
   "& .country-city-auto":{
    "& .MuiInput-root.Mui-focused .MuiInputAdornment-positionStart img": {
      filter: "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)"
    },
    "& .MuiInputAdornment-positionStart":{
      marginLeft:"8px",
      marginRight:"13px !important"
    },
  },
  "& .purple-icon":{
      "& .MuiInputAdornment-root img":{
      filter: "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)"
    }
  },
   "& .MuiAutocomplete-input":{
    fontWeight:600,
    fontSize:"16px",
    padding:"6px 0px 6px 0px !important"
  },
  "& .MuiInputBase-input":{
    fontSize:"16px",
    fontWeight:600,
    '@media (min-width:1200px) and (max-width:1600px)': {
      fontSize:"13px !important",
    },
  },
  '& .MuiInputBase-input::placeholder': {
    fontWeight: 400,
  },
  "& .sign-up-eye-icon":{
    color:"#A6A6A6"
  },
  '& .MuiFormControl-fullWidth': {
    '@media (min-width:1200px) and (max-width:1600px)': {
    marginBottom:"16px !important",
    },
    marginBottom: "40px",
  },
  '& .phon-style': {
    '@media (min-width:1200px) and (max-width:1600px)': {
    marginBottom:"16px !important",
    },
     marginBottom:"40px"
  }
}));

const FormContainer = styled(Box)(({theme}) => ({
  width: "100%", 
  maxWidth: "550px", 
  paddingTop: "40px",
  [theme.breakpoints.down("sm")]: { maxWidth: "fit-content" },
  [theme.breakpoints.down("xs")]: { maxWidth: "100%" },
}))

const BoxContainer = styled(Box)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 0px",
  marginBottom:"47px",
  [theme.breakpoints.down("xs")]: { flexDirection:"column", gap:"10px" },
}));

export interface SignUpModal {
  user_name: string;
  country: string;
  city: string;
  email: string;
  full_phone_number: string;
  password: string;
  password_confirmation: string;
  country_code: string;
}
export interface CatDogFormModal {
  name: string;
  breed: string;
  size: string;
  weight: string;
  fur: string;
  gender: string;
  birthdate: string;
  species: string;
}

interface FormErrors {
  [key: string]: string;
}

interface FormTouched {
  [key: string]: boolean;
}

const signUpSchema = Yup.object().shape({
  user_name: Yup.string()
    .required("*Please Enter Name")
    .matches(/^[A-Za-z\s]*$/, "User Name should only contain letters")
    .trim(),
  city: Yup.string().required("*Please Select City").trim(),
  country: Yup.string().required("*Please Select Country").trim(),
  full_phone_number: Yup.string()
    .required("*Your Enter Phone")
    .trim(),
  email: Yup.string()
    .email("Invalid email address")
    .required("*Please Enter Email")
    .test("email validation", "Invalid email address", (value) => {
      if (!value) return false;
      return /^[a-zA-Z0-9._+~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
    })
    .trim(),

  password: Yup.string()
    .required("*Please Enter Password.")
    .test(
      "password-complexity",
      "Password should include at least 8 characters, one number, and one letter.",
      (value) => {
        if (!value) return false;
        return /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(value);
      }
    )
    .trim(),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password")], "*Passwords does not match.")
    .required("*Please Enter Confirm Password."),
});
const catDogSchema = Yup.object().shape({
  name: Yup.string()
    .required("*Please Enter Pet's Name")
    .matches(/^[A-Za-z\s]*$/, "Pet Name should only contain letters")
    .trim(),
  breed: Yup.string().required("*Please Enter Your Pet's Breed").trim(),
  size: Yup.string().required("*Please Select Size").trim(),
  weight: Yup.string().required("*Please Select Weight").trim(),
  fur: Yup.string().required("*Please Select Fur").trim(),
  gender: Yup.string().required("*Please Select Gender").trim(),
  birthdate: Yup.string()
    .required("*Please Enter Your Pet's BirthDate")
    .test("is-not-future-date", "Enter valid Birthdate ", (value) =>
      moment(value, "DD-MM-YYYY").isSameOrBefore(moment())
    )
    .trim(),
});
const fishBirdSchema = Yup.object().shape({
  name: Yup.string()
    .required("*Please Enter Pet's Name")
    .matches(/^[A-Za-z\s]*$/, "Pet Name should only contain letters")
    .trim(),
  birthdate: Yup.string()
    .required("*Please Enter Your Pet's BirthDate")
    .test("is-not-future-date", "Enter valid BirthDate ", (value) =>
      moment(value, "DD-MM-YYYY").isSameOrBefore(moment())
    )
    .trim(),
});
const rabbitReptileSchema = Yup.object().shape({
  name: Yup.string()
    .required("*Please Enter Pet's Name")
    .matches(/^[A-Za-z\s]*$/, "Pet Name should only contain letters")
    .trim(),
  species: Yup.string().required("*Please Select Species").trim(),
  birthdate: Yup.string()
    .required("*Please Enter Your Pet's BirthDate")
    .test("is-not-future-date", "Enter valid BirthDate ", (value) =>
      moment(value, "DD-MM-YYYY").isSameOrBefore(moment())
    )
    .trim(),
});

const rabbitWeightSchema = rabbitReptileSchema.concat(
  Yup.object().shape({
    weight: Yup.string().required("*Please Select Weight").trim(),
  })
);
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat"
  },
});
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getErrorAndHelperText = (
    field: string,
    errors: FormErrors,
    touched: FormTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    let helperText: string = isError ? errors[field] ?? "" : "";
    helperText = this.transCustomerSignUp(helperText);
    return { isError, helperText };
  };

  getPetContainerStyle = (selectedPetName: string, type?: string) => {
    const petName =
      type === "subpet"
        ? this.state.selectedSubPetName
        : this.state.selectedPetName;
    const borderColor = petName === selectedPetName ? "#6F05EA" : "#CCCCCC";

    return { borderColor };
  };
 
  getPetImage = (selectedPetName: string, type?: string) => {
    const icons: any = {
      dog: { selected: dogIcon, grey: greyDogIcon },
      cat: { selected: catIcon, grey: greyCatIcon },
      other: { selected: otherIcon, grey: greyOtherIcon },
      bird: { selected: birdIcon, grey: greyBirdIcon },
      rabbit: { selected: rabbitIcon, grey: greyRabbitIcon },
      fish: { selected: fishIcon, grey: greyFishIcon },
      reptile: { selected: reptileIcon, grey: greyReptileIcon },
    };

    const selectedName =
      type === "subpet"
        ? this.state.selectedSubPetName
        : this.state.selectedPetName;
    const petIcons = icons[selectedPetName];
    return this.renderPetImage(petIcons, selectedName, selectedPetName);
  };

  renderPetImage = (
    petIcons: {
      selected: string;
      grey: string;
    },
    selectedName: string,
    selectedPetName: string
  ) => {
    if (!petIcons) {
      return undefined;
    }

    const srcImage =
      selectedName === selectedPetName ? petIcons.selected : petIcons.grey;
    return <img src={srcImage} className="pet-image" alt="Pet Icon" />;
  };

  renderTermCondition = () => {
    return (
      <Box style={{ marginBottom: "40px" }}>
        <TermsConditions navigation={""} id={"terms-condition"} />
      </Box>
    );
  };

  renderRegisterButton = (buttonName: string) => {
    return (
      <>
        {this.state.signUpError && (
          <Typography
            style={{ color: "#EF4444", marginTop: "20px", textAlign: "center" }}
            data-test-id="signUp-error"
          >
            {this.state.signUpError}
          </Typography>
        )}
        <BoxContainer> 
        {this.state.stepNumber === 2 && <BackButton data-test-id="back-btn" fullWidth onClick={this.handleBackClick}>{this.transCustomerSignUp("Back")}</BackButton>}
          <SubmitButton
              className="submit-btn"
              type="submit"
              fullWidth
              endIcon={!this.state.isSignUpLoading ? <img src={forwardArrowIcon} alt="forward-arrow" /> : ""}
              isLoading={this.state.isSignUpLoading}
              buttonText={buttonName}
              data-test-id="btnEmailLogIn"
            />
        </BoxContainer>
      </>
    );
  };

  renderUserInfoForm = () => {
    return (
      <Formik
        initialValues={this.state.step1Data}
        validationSchema={signUpSchema}
        enableReinitialize
        onSubmit={(values) => {
          this.userSignUp(values);
        }}
        data-test-id="user-signup-form"
      >
        {({
          errors,
          touched,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          values,
        }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <InputField
                  {...getFieldProps("user_name")}
                  fullWidth
                  id="user_name"
                  label={this.transCustomerSignUp("Name")}
                  placeholder={this.transCustomerSignUp("Your name")}
                  type="text"
                  startAdornment={<img src={nameIcon} />}
                  error={
                    this.getErrorAndHelperText(
                      "user_name",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  helperText={
                    this.getErrorAndHelperText(
                      "user_name",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  style={webStyles.commonTextFieldStyle}
                  data-test-id="userinfo-name"
                />
                <>
                  <InputLabel
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      marginBottom:"8px",
                      color: "rgb(51, 65, 85)",
                    }}
                  >
                    {this.transCustomerSignUp("Country")}
                  </InputLabel>
                  <Autocomplete
                    disableClearable
                    id="country"
                    className={`${values.country ? "purple-icon" : ""} country-city-select country-city-auto`}
                    style={webStyles.commonTextFieldStyle}
                    data-test-id="input-country"
                    value={values.country}
                    options={this.state.countries.map(
                      (options) => options.label
                    )}
                    getOptionLabel={(option) => option}
                    onChange={(_event, newValue) => {
                      setFieldValue("country", newValue);
                      this.handleCountryChange(newValue);
                    }}
                    popupIcon={<ExpandMoreRoundedIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={this.transCustomerSignUp("Your country")}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={countryIcon} />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={
                          this.getErrorAndHelperText(
                            "country",
                            errors as FormErrors,
                            touched as FormTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "country",
                            errors as FormErrors,
                            touched as FormTouched
                          ).helperText
                        }
                      />
                    )}
                  />
                </>
                <>
                <InputLabel
                    style={{
                      marginBottom:"8px",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "rgb(51, 65, 85)",
                    }}
                  >
                    {this.transCustomerSignUp("City")}
                  </InputLabel>
                  <Autocomplete
                    disableClearable
                    id="city"
                    data-test-id="input-city"
                    style={webStyles.commonTextFieldStyle}
                    className={`${values.city ? "purple-icon" : ""} country-city-select country-city-auto`}
                    value={values.city}
                    options={this.state.cities.map(
                      (options) => options.label
                    )}
                    getOptionLabel={(option) => option}
                    onChange={(_event, newValue) => {
                      setFieldValue("city", newValue);
                    }}
                    popupIcon={<ExpandMoreRoundedIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={this.transCustomerSignUp("Your city")}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={cityIcon} />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={
                          this.getErrorAndHelperText(
                            "city",
                            errors as FormErrors,
                            touched as FormTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "city",
                            errors as FormErrors,
                            touched as FormTouched
                          ).helperText
                        }
                      />
                    )}
                  />
                </>
                <InputField
                  {...getFieldProps("email")}
                  placeholder={this.transCustomerSignUp("Your email address")}
                  type="text"
                  id="email"
                  style={webStyles.commonTextFieldStyle}
                  label={this.transCustomerSignUp("Email")}
                  helperText={
                    this.getErrorAndHelperText(
                      "email",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  fullWidth
                  error={
                    this.getErrorAndHelperText(
                      "email",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  startAdornment={<img src={emailIcon} />}
                />
                <Box className={'phon-style'}>
                  <InputLabel
                    style={{
                      color: "#334155",
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontWeight: 600,
                      marginBottom:"8px",
                    }}
                  >
                    {this.transCustomerSignUp("Phone")}
                  </InputLabel>
                  <PhoneInput
                    {...getFieldProps("full_phone_number")}
                    country={
                      this.state.isoCountryName.toString().toLowerCase() || "pt"
                    }
                    buttonStyle={{ border: "none", background: "transparent" }}
                    placeholder="Your contact number"
                    inputStyle={{
                      width: "100%",
                      height: "32px",
                      border: "none",
                      fontWeight:600,
                      fontSize:"16px",
                      borderBottom:
                        Boolean(errors.full_phone_number) &&
                        touched.full_phone_number
                          ? "2px solid #EF4444"
                          : "1px solid #B7B7B7",
                      borderRadius: "0px",
                      paddingLeft: "45px",
                    }}
                    dropdownStyle={{ fontSize: "13px" }}
                    value={values.country_code + values.full_phone_number}
                    onChange={(phone, data: { dialCode: string }) => {
                      const phoneNumber = phone.substring(data.dialCode.length);
                      setFieldValue("full_phone_number", phoneNumber);
                      setFieldValue("country_code", data.dialCode);
                    }}
                    data-test-id="userinfo-phone"
                  />
                  {Boolean(errors.full_phone_number) &&
                    touched.full_phone_number && (
                      <FormHelperText style={{ color: "#f44336" }}>
                         {this.getErrorAndHelperText(
                            "full_phone_number",
                            errors as FormErrors,
                            touched as FormTouched
                          ).helperText}
                      </FormHelperText>
                    )}   
                </Box>
                <InputField
                  {...getFieldProps("password")}
                  style={webStyles.commonTextFieldStyle}
                  label={this.transCustomerSignUp("Password")}
                  fullWidth
                  type={this.state.enablePasswordField ? "password" : "text"}
                  startAdornment={<img src={passwordIcon}/>}
                  endAdornment={this.renderEndAdornment(
                    "password",
                    this.state.enablePasswordField
                  )}
                  placeholder={this.transCustomerSignUp("Password")}
                  error={
                    this.getErrorAndHelperText(
                      "password",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  helperText={
                    this.getErrorAndHelperText(
                      "password",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                />
                <InputField
                  {...getFieldProps("password_confirmation")}
                  type={
                    this.state.enableConfirmPasswordField ? "password" : "text"
                  }
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder={this.transCustomerSignUp("Confirm Password")}
                  style={{marginBottom:"20px"}}
                  label={this.transCustomerSignUp("Confirm Password")}
                  startAdornment={<img src={passwordIcon}/>}
                  fullWidth
                  error={
                    this.getErrorAndHelperText(
                      "password_confirmation",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  endAdornment={this.renderEndAdornment(
                    "confirmPassword",
                    this.state.enableConfirmPasswordField
                  )}
                  helperText={
                    this.getErrorAndHelperText(
                      "password_confirmation",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                />
                {this.renderTermCondition()}
                {this.renderRegisterButton(this.transCustomerSignUp("Next"))}
              </form>
            </>
          );
        }}
      </Formik>
    );
  };

  renderCatDogInfoForm = () => {
    return (
      <Formik
        initialValues={this.state.step2Data}
        validationSchema={catDogSchema}
        onSubmit={(values) => {
          const petData = {...values};
          this.petSignUp(petData);
        }}
        data-test-id="cat-dog-form"
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => {
          const handleValueChange = (event:React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event);
            this.handleStep2Change(event)
          }
          return (
            <>
              <form onSubmit={handleSubmit}>
                <InputField
                  {...getFieldProps("name")}
                  fullWidth
                  onChange={(event) => handleValueChange(event)}
                  id="name"
                  label={this.transCustomerSignUp("Pet name")}
                  placeholder={this.transCustomerSignUp("Your pet's name")}
                  startAdornment={<img src={petNameIcon} />}
                  style={webStyles.commonTextFieldStyle}
                  type="text"
                  error={
                    this.getErrorAndHelperText(
                      "name",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  helperText={
                    this.getErrorAndHelperText(
                      "name",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  data-test-id="dog-cat-name"
                />
                <CustomLabel>{this.transCustomerSignUp("Breed")}</CustomLabel>
                <Autocomplete
                  disableClearable
                  {...getFieldProps("breed")}
                  id="breed"
                  data-test-id="dog-cat-breed"
                  style={webStyles.commonTextFieldStyle}
                  className={`country-city-select country-city-auto`}
                  options={this.getBreedList()}
                  getOptionLabel={(option) => option}
                  value={values.breed}
                  onChange={(_event, newValue) => {
                    this.setBreedState(newValue as string)
                    setFieldValue("breed", newValue);
                  }}
                  popupIcon={<ExpandMoreRoundedIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={this.transCustomerSignUp("Your pet's Breed")}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (<InputAdornment position="start"></InputAdornment>),
                      }}
                      fullWidth
                      error={
                        this.getErrorAndHelperText(
                          "breed",
                          errors as FormErrors,
                          touched as FormTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "breed",
                          errors as FormErrors,
                          touched as FormTouched
                        ).helperText
                      }
                    />
                  )}
                />
                <Box style={{ position: "relative" }}>
                  <CommonDatePicker
                    selectedDate={this.state.birthDate}
                    maxDate={new Date(Date.now())}
                    handleDateChange={(date) => {
                      setFieldValue(
                        "birthdate",
                        moment(date).format("DD-MM-YYYY")
                      );
                      this.setBirthDate(date);
                    }}
                    data-test-id="cat-dog-birth-date"
                  />
                  <InputField
                    {...getFieldProps("birthdate")}
                    name="birthdate"
                    label={this.transCustomerSignUp("Birth Date")}
                    fullWidth
                    placeholder={this.transCustomerSignUp("Your pet's birthdate")}
                    id="birthdate"
                    data-test-id="dog-cat-birth-date"
                    helperText={
                      this.getErrorAndHelperText(
                        "birthdate",
                        errors as FormErrors,
                        touched as FormTouched
                      ).helperText
                    }
                    error={
                      this.getErrorAndHelperText(
                        "birthdate",
                        errors as FormErrors,
                        touched as FormTouched
                      ).isError
                    }
                    style={{ marginBottom: "40px" }}
                  />
                </Box>
                <CommonSelect
                  {...getFieldProps("gender")}
                  id="gender"
                  name="gender"
                  onBlur={handleBlur}
                  placeholder={this.transCustomerSignUp("Male")}
                  label={this.transCustomerSignUp("Gender")}
                  startAdornment={<img src={genderIcon} />}
                  onChange={(event) => handleValueChange(event)}
                  data-test-id="dog-cat-gender"
                  list={[
                    { label: this.transCustomerSignUp("Male"), value: "Male" },
                    { label: this.transCustomerSignUp("Female"), value: "Female" },
                  ]}
                  error={
                    this.getErrorAndHelperText(
                      "gender",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  helperText={
                    this.getErrorAndHelperText(
                      "gender",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  fullWidth
                />
                <CommonSelect
                  {...getFieldProps("fur")}
                  id="fur"
                  fullWidth
                  placeholder={this.transCustomerSignUp("Fur length")}
                  onChange={(event) => handleValueChange(event)}
                  startAdornment={<img src={FurIcon} />}
                  label={this.transCustomerSignUp("Fur")}
                  onBlur={handleBlur}
                  name="fur"
                  list={[
                    { label: this.transCustomerSignUp("Hairless"), value: "Hairless" },
                    {
                      label: this.transCustomerSignUp("Short Coat"),
                      value: "Short Coat",
                    },
                    { label: this.transCustomerSignUp("Medium Coat"), value: "Medium Coat" },
                    { label: this.transCustomerSignUp("Long Coat"), value: "Long Coat" },
                  ]}
                  data-test-id="dog-cat-fur"
                  error={
                    this.getErrorAndHelperText(
                      "fur",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  helperText={
                    this.getErrorAndHelperText(
                      "fur",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                />
                <CommonSelect
                  {...getFieldProps("size")}
                  label={this.transCustomerSignUp("Size")}
                  name="size"
                  fullWidth
                  placeholder={this.transCustomerSignUp("Select a range")}
                  onChange={(event) => handleValueChange(event)}
                  id="size"
                  onBlur={handleBlur}
                  list={[
                    { label: this.transCustomerSignUp("Toy (Less than 30 cm)"), value: "Toy (Less then 30 cm)" },
                    {
                      label: this.transCustomerSignUp("Small (30-44 cm)"),
                      value: "Small (30-44 cm)",
                    },
                    { label: this.transCustomerSignUp("Medium (45-64 cm)"), value: "Medium (45-64 cm)" },
                    { label: this.transCustomerSignUp("Large (65-89 cm)"), value: "Large (65-89 cm)" },
                  ]}
                  helperText={
                    this.getErrorAndHelperText(
                      "size",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  error={
                    this.getErrorAndHelperText(
                      "size",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                  data-test-id="dog-cat-size"
                  startAdornment={<img src={sizeIcon} />}
                />
                <CommonSelect
                  {...getFieldProps("weight")}
                  id="weight"
                  name="weight"
                  onChange={(event) => handleValueChange(event)}
                  fullWidth
                  placeholder={this.transCustomerSignUp("Select a range")}
                  label={this.transCustomerSignUp("Weight")}
                  onBlur={handleBlur}
                  list={[
                    { label: this.transCustomerSignUp("Less then 5 kg"), value: "less then 5 kg" },
                    { label: this.transCustomerSignUp("5-14 kg"), value: "5-14 kg" },
                    {
                      label: this.transCustomerSignUp("15-24 kg"),
                      value: this.transCustomerSignUp("15-24 kg"),
                    },
                    { label: this.transCustomerSignUp("25-44 kg"), value: "25-44 kg" },
                  ]}
                  startAdornment={<img src={weightIcon} />}
                  helperText={
                    this.getErrorAndHelperText(
                      "weight",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  data-test-id="dog-cat-weight"
                  error={
                    this.getErrorAndHelperText(
                      "weight",
                      errors as FormErrors,
                      touched as FormTouched
                    ).isError
                  }
                />
                  {this.renderRegisterButton(this.transCustomerSignUp("Register"))}
              </form>
            </>
          );
        }}
      </Formik>
    );
  };

  renderFishBirdInfoForm = () => {
    return (
      <Formik
        key={this.state.selectedSubPetName} 
        initialValues={this.state.step2Data}
        validationSchema={fishBirdSchema}
        enableReinitialize={true} 
        onSubmit={(values) => {
          const petData = { ...values, gender: "" };
          this.petSignUp(petData);
        }}
        data-test-id="fish-bird-form"
      >
        {({ errors, touched, handleSubmit, getFieldProps, setFieldValue,handleChange }) => {
          const handleFishBirdChange = (event:React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event);
            this.handleStep2Change(event)
          }
          return(
          <>
            <form onSubmit={handleSubmit}>
              <InputField
                {...getFieldProps("name")}
                id="name"
                type="text"
                placeholder={this.transCustomerSignUp("Your pet's name")}
                onChange={(event) => handleFishBirdChange(event)}
                label={this.transCustomerSignUp("Pet name")}
                startAdornment={<img src={petNameIcon} />}
                style={webStyles.commonTextFieldStyle}
                error={
                  this.getErrorAndHelperText(
                    "name",
                    errors as FormErrors,
                    touched as FormTouched
                  ).isError
                }
                fullWidth
                data-test-id="fish-bird-name"
                helperText={
                  this.getErrorAndHelperText(
                    "name",
                    errors as FormErrors,
                    touched as FormTouched
                  ).helperText
                }
              />
              <Box style={{ position: "relative" }}>
                <CommonDatePicker
                  data-test-id="fish-bird-birth-date"
                  selectedDate={
                    this.state.birthDate
                      ? moment(this.state.birthDate, "DD-MM-YYYY").toDate()
                      : null
                  }
                  maxDate={new Date(Date.now())}
                  handleDateChange={(date: Date | null) => {
                    setFieldValue(
                      "birthdate",
                      moment(date).format("DD-MM-YYYY")
                    );
                    this.setBirthDate(date);
                  }}
                />
                <InputField
                  {...getFieldProps("birthdate")}
                  label={this.transCustomerSignUp("Birth Date")}
                  id="birthdate"
                  name="birthdate"
                  placeholder={this.transCustomerSignUp("Your pet's birthdate")}
                  fullWidth
                  error={Boolean(errors.birthdate) && touched.birthdate}
                  helperText={
                    this.getErrorAndHelperText(
                      "birthdate",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  style={{ marginBottom: "40px" }}
                />
              </Box>
              {this.renderRegisterButton(this.transCustomerSignUp("Register"))}
            </form>
          </>
        )}}
      </Formik>
    );
  };

  renderWeight = (getFieldProps: any, handleChange: any, handleBlur: any, errors: any, touched: any) => {
    return (
      <CommonSelect
        {...getFieldProps("weight")}
        id="weight"
        name="weight"
        label={this.transCustomerSignUp("Weight")}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={this.transCustomerSignUp("Select a range")}
        fullWidth
        error={
          this.getErrorAndHelperText(
            "weight",
            errors as FormErrors,
            touched as FormTouched
          ).isError
        }
        list={[
          { label: this.transCustomerSignUp("Less than 5 kg"), value: "less then 5 kg" },
          { label: this.transCustomerSignUp("5-14 kg"), value: "5-14 kg" },
          { label: this.transCustomerSignUp("15-24 kg"), value: "15-24 kg" },
          { label: this.transCustomerSignUp("25-44 kg"), value: "25-44 kg" },
        ]}
        data-test-id="rabbit-reptile-weight"
        helperText={
          this.getErrorAndHelperText("weight", errors, touched).helperText
        }
      />
    );
  };

  renderRabbitReptileInfoForm = () => {
    const isRabbit = checkCondition(Boolean(this.state.step2Data.species === "Rabbit"),true,false);
    const schema = checkCondition(Boolean(isRabbit),rabbitWeightSchema,rabbitReptileSchema);
    return (
      <Formik
        key={this.state.selectedSubPetName} 
        initialValues={this.state.step2Data}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values) => {
          const petData = { ...values, gender: "" };
          this.petSignUp(petData);
        }}
        data-test-id="rabbit-reptile-form"
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => {
          const handleRabbitReptileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event);
            this.handleStep2Change(event)
          }
          return(
          <>
            <form onSubmit={handleSubmit}>
              <InputField
                {...getFieldProps("name")}
                fullWidth
                type="text"
                onChange={(event) => handleRabbitReptileChange(event)}
                style={webStyles.commonTextFieldStyle}
                id="name"
                placeholder={this.transCustomerSignUp("Your pet's name")}
                label={this.transCustomerSignUp("Pet name")}
                data-test-id="rabbit-reptile-name"
                helperText={
                  this.getErrorAndHelperText(
                    "name",
                    errors as FormErrors,
                    touched as FormTouched
                  ).helperText
                }
                error={
                  this.getErrorAndHelperText(
                    "name",
                    errors as FormErrors,
                    touched as FormTouched
                  ).isError
                }
                startAdornment={<img src={petNameIcon} />}
              />
              <CommonSelect
                {...getFieldProps("species")}
                id="species"
                name="species"
                label={this.transCustomerSignUp("Species")}
                onChange={(event) => handleRabbitReptileChange(event)}
                onBlur={handleBlur}
                placeholder={this.transCustomerSignUp("Your pet's species")}
                fullWidth
                error={
                  this.getErrorAndHelperText(
                    "species",
                    errors as FormErrors,
                    touched as FormTouched
                  ).isError
                }
                list={
                  this.state.selectedSubPetName === "rabbit"
                    ? [
                        {
                          label: this.transCustomerSignUp("Rabbit"),
                          value: "Rabbit",
                        },
                        {
                          label: this.transCustomerSignUp("Genui Pig"),
                          value: "Genui Pig",
                        },
                        {
                          label: this.transCustomerSignUp("Hamster"),
                          value: "Hamster",
                        },
                        { label: this.transCustomerSignUp("Rat"), value: "Rat" },
                        {
                          label: this.transCustomerSignUp("Gerbil"),
                          value: "Gerbil",
                        },
                      ]
                    : [
                        {
                          label: this.transCustomerSignUp("Dragon"),
                          value: "Dragon",
                        },
                        { label: this.transCustomerSignUp("Snake"), value: "Snake" },
                        { label: this.transCustomerSignUp("Gecko"), value: "Gecko" },
                        {
                          label: this.transCustomerSignUp("Turtles"),
                          value: "Turtles",
                        },
                      ]
                }
                data-test-id="rabbit-reptile-species"
                helperText={
                  this.getErrorAndHelperText(
                    "species",
                    errors as FormErrors,
                    touched as FormTouched
                  ).helperText
                }
              />
              {isRabbit && this.renderWeight(getFieldProps,handleRabbitReptileChange,handleBlur, errors, touched)}
              <Box style={{ position: "relative" }}>
                <CommonDatePicker
                  selectedDate={this.state.birthDate}
                  handleDateChange={(date: Date | null) => {
                    setFieldValue(
                      "birthdate",
                      moment(date).format("DD-MM-YYYY")
                    );
                    this.setBirthDate(date);
                  }}
                  data-test-id="rabbit-reptile-birth-date"
                  maxDate={new Date(Date.now())}
                />
                <InputField
                  {...getFieldProps("birthdate")}
                  id="birthdate"
                  fullWidth
                  label={this.transCustomerSignUp("Birth Date")}
                  placeholder={this.transCustomerSignUp("Your pet's birthdate")}
                  style={{ marginBottom: "40px" }}
                  helperText={
                    this.getErrorAndHelperText(
                      "birthdate",
                      errors as FormErrors,
                      touched as FormTouched
                    ).helperText
                  }
                  error={Boolean(errors.birthdate) && touched.birthdate}
                  name="birthdate"
                />
              </Box>
              {this.renderRegisterButton(this.transCustomerSignUp("Register"))}
            </form>
          </>
        )}}
      </Formik>
    );
  };
  renderTitle = () => {
    return (
      <PageTitle>
        {this.state.stepNumber === 1
          ? this.transCustomerSignUp(configJSON.form1Title)
          : this.transCustomerSignUp(configJSON.form2Title)}
      </PageTitle>
    );
  };
  handleSubPetFormRender = () => {
    switch (this.state.selectedSubPetName) {
      case "fish":
        return  this.renderFishBirdInfoForm();
      case "bird":
        return this.renderFishBirdInfoForm();
      case "rabbit":
        return this.renderRabbitReptileInfoForm();
      case "reptile":
        return this.renderRabbitReptileInfoForm();
      default:
        break;
    }
  };
 
  renderCatDogForm = () => (
    <>
      {this.state.selectedPetName === "dog" &&
        this.renderCatDogInfoForm()}
      {this.state.selectedPetName === "cat" &&
        this.renderCatDogInfoForm()}
    </>
  );

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <Box sx={webStyles.pageWrapper}>
          <ImageContainer>
            <img className="Image" src={signUpImage} />
            <Box style={{ position: "absolute" }}>
              <Typography
                style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                {this.transCustomerSignUp(configJSON.welComeText)}
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  marginTop: "40px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {this.transCustomerSignUp("Explore and access all kinds of service")}{" "}
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {this.transCustomerSignUp("fit best for your pet.")}
              </Typography>
            </Box>
          </ImageContainer>
          <RegisterInfoContainer>
            <Box
            className="register-info">
              
              {this.renderTitle()}

              <FormContainer>
                {this.state.stepNumber === 1 ? (
                  this.renderUserInfoForm()
                ) : (
                  <>
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        marginBottom: "9px",
                      }}
                    >
                      {this.transCustomerSignUp("Your animal is a:")}
                    </Typography>
                    <ImageCardGrid
                      container
                      spacing={2} >
                      <Grid item lg={4} sm={12}>
                        <PetGridContainer
                          style={this.getPetContainerStyle("dog")}
                          onClick={(event) => {
                            this.setState({ signUpError: "", birthDate: new Date(Date.now())});
                            this.handleClickPetSelect("dog");
                          }}
                          data-test-id="dog-card"
                        >
                          {this.getPetImage("dog")}
                          <Typography style={this.getPetTypographyStyle("dog")}>
                            {this.transCustomerSignUp("Dog")}
                          </Typography>
                        </PetGridContainer>
                      </Grid>
                      <Grid item lg={4} sm={12}>
                        <PetGridContainer
                          style={this.getPetContainerStyle("cat")}
                          data-test-id="cat-card"
                          onClick={(event) => {
                            this.setState({ signUpError: "", birthDate: new Date(Date.now())});
                            this.handleClickPetSelect("cat");
                          }}
                        >
                          {this.getPetImage("cat")}
                          <Typography style={this.getPetTypographyStyle("cat")}>
                            {this.transCustomerSignUp("Cat")}
                          </Typography>
                        </PetGridContainer>
                      </Grid>
                      <Grid item lg={4} sm={12}>
                        <PetGridContainer
                          data-test-id="other-card"
                          className="pet-typography"
                          style={this.getPetContainerStyle("other")}
                          onClick={(event) => {
                            this.handleClickPetSelect("other");
                            this.setState({ isBackDropOpen: true, signUpError:"" });
                          }}
                        >
                          <>
                            {this.state.selectedPetName === "other" &&
                            this.state.selectedSubPetName !== "" ? (
                              <>
                                <img
                                  src={
                                    subPetIconMap[
                                      this.state
                                        .selectedSubPetName as keyof typeof subPetIconMap
                                    ]
                                  }
                                  className="pet-image"
                                />
                                <Typography
                                  style={this.getPetTypographyStyle("other", "" ,"mediaQuery", true)}
                                  className={`${this.state.selectedSubPetName === "rabbit" ? "rabbit-typo" : ""}`}
                                >
                                  {this.state.selectedSubPetName === "rabbit"
                                    ? this.transCustomerSignUp("Rabbit/Rodent")
                                    : this.transCustomerSignUp(this.state.selectedSubPetName
                                        .charAt(0)
                                        .toUpperCase() +
                                      this.state.selectedSubPetName.slice(1))}
                                </Typography>
                              </>
                            ) : (
                              <>
                                {this.state.selectedPetName === "other" ? (
                                  <>
                                    {this.getPetImage("other")}
                                    <Typography
                                      style={this.getPetTypographyStyle(
                                        "other"
                                      )}
                                    >
                                      {this.transCustomerSignUp("Other")}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    {this.getPetImage("other")}
                                    <Typography>{this.transCustomerSignUp("Other")}</Typography>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        </PetGridContainer>
                      </Grid>
                    </ImageCardGrid>
                      {this.renderCatDogForm()}
                    {this.state.selectedPetName === "other" && (
                      <>
                        <CommonBackdrop
                          open={this.state.isBackDropOpen}
                          onClick={this.handleClickBackDrop}
                        >
                          <OtherOptionContainer>
                            <Box
                              className="option-box">
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <PetOptionBackDropContainer
                                  data-test-id="rabbit-card"
                                  style={this.getPetContainerStyle(
                                    "rabbit",
                                    "subpet"
                                  )}
                                  onClick={() =>
                                    this.handleSubSelectPet("rabbit")
                                  }
                                >
                                  {this.getPetImage("rabbit", "subpet")}
                                  <Typography
                                    style={this.getPetTypographyStyle(
                                      "rabbit",
                                      "subpet",
                                      "mediaQuery",
                                      true
                                    )}
                                    data-test-id="rabbit-title"
                                  >
                                    {this.transCustomerSignUp("Rabbit/Rodent")}
                                  </Typography>
                                </PetOptionBackDropContainer>
                                <PetOptionBackDropContainer
                                  style={this.getPetContainerStyle(
                                    "bird",
                                    "subpet"
                                  )}
                                  onClick={() =>
                                    this.handleSubSelectPet("bird")
                                  }
                                  data-test-id="bird-card"
                                >
                                  {this.getPetImage("bird", "subpet")}
                                  <Typography
                                    style={this.getPetTypographyStyle(
                                      "bird",
                                      "subpet"
                                    )}
                                  >
                                    {this.transCustomerSignUp("Bird")}
                                  </Typography>
                                </PetOptionBackDropContainer>
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <PetOptionBackDropContainer
                                  style={this.getPetContainerStyle(
                                    "reptile",
                                    "subpet"
                                  )}
                                  onClick={() =>
                                    this.handleSubSelectPet("reptile")
                                  }
                                  data-test-id="reptile-card"
                                >
                                  {this.getPetImage("reptile", "subpet")}
                                  <Typography
                                    style={this.getPetTypographyStyle(
                                      "reptile",
                                      "subpet"
                                    )}
                                  >
                                    {this.transCustomerSignUp("Reptile")}
                                  </Typography>
                                </PetOptionBackDropContainer>
                                <PetOptionBackDropContainer
                                  style={this.getPetContainerStyle(
                                    "fish",
                                    "subpet"
                                  )}
                                  data-test-id="fish-card"
                                  onClick={() =>
                                    this.handleSubSelectPet("fish")
                                  }
                                >
                                  {this.getPetImage("fish", "subpet")}
                                  <Typography
                                    style={this.getPetTypographyStyle(
                                      "fish",
                                      "subpet"
                                    )}
                                  >
                                    {this.transCustomerSignUp("Fish")}
                                  </Typography>
                                </PetOptionBackDropContainer>
                              </Box>
                            </Box>
                            <Button
                              data-test-id="cancel-button"
                              variant="contained"
                              fullWidth
                              onClick={(event) => {
                                this.setState({ isBackDropOpen: false });
                              }}
                              style={{
                                border: "1px solid #B7B7B7",
                                borderRadius: "100px",
                                padding: "10px 16px",
                                height: "53px",
                                fontSize: "16px",
                                gap: "10px",
                                fontWeight: 700,
                                textTransform: "none",
                                color: "#B7B7B7",
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                marginTop: "12px",
                              }}
                            >
                              {this.transCustomerSignUp("Cancel")}
                            </Button>
                          </OtherOptionContainer>
                        </CommonBackdrop>
                        {this.handleSubPetFormRender()}
                      </>
                    )}
                  </>
                )}
              </FormContainer>
            </Box>
          </RegisterInfoContainer>
        </Box>
        <AppFooter navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CustomLabel = styled(InputLabel)({
  marginBottom: "8px",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "22px",
  color: "rgb(51, 65, 85)",
})
// Customizable Area End
