export const enCustomerSignUp = {
    "Welcome to Petzai":"Welcome to Petzai",
    "Explore and access all kinds of service":"Explore and access all kinds of service",
    "fit best for your pet.":"fit best for your pet.",
    "Please tell us about yourself": "Please tell us about yourself",
    "Now let's talk about your pet":"Now let's talk about your pet",
    "Your animal is a:":"Your animal is a:",
    //FIELD
    "Name":"Name",
    "Country":"Country",
    "City":"City",
    "Email":"Email",
    "Phone":"Phone",
    "Password":"Password",
    "Confirm Password":"Confirm Password",
    "Pet name":"Pet name",
    "Breed":"Breed",
    "Birth Date":"Birth Date",
    "Gender":"Gender",
    "Fur":"Fur",
    "Size":"Size",
    "Weight":"Weight",
    "Species":"Species",
    //ERROR
    "*Please Enter Name":"*Please Enter Name",
    "User Name should only contain letters":"User Name should only contain letters",
    "*Please Select Country":"*Please Select Country",
    "*Please Select City":"*Please Select City",
    "*Please Enter Email":"*Please Enter Email",
    "Invalid email address":"Invalid email address",
    "*Your Enter Phone":"*Your Enter Phone",
    "*Please Enter Password.":"*Please Enter Password.",
    "Password should include at least 8 characters, one number, and one letter.":"Password should include at least 8 characters, one number, and one letter.",
    "*Please Enter Confirm Password.":"*Please Enter Confirm Password.",
    "*Passwords does not match.":"*Passwords does not match.",
    "*Please Enter Pet's Name":"*Please Enter Pet's Name",
    "*Please Enter Your Pet's Breed":"*Please Enter Your Pet's Breed",
    "*Please Enter Your Pet's BirthDate":"*Please Enter Your Pet's BirthDate",
    "*Please Select Gender":"*Please Select Gender",
    "*Please Select Fur":"*Please Select Fur",
    "*Please Select Size":"*Please Select Size",
    "*Please Select Weight":"*Please Select Weight",
    "*Please Select Species":"*Please Select Species",
    //PLACEHOLDER
    "Your name":"Your name",
    "Your country":"Your country",
    "Your city":"Your city",
    "Your email address":"Your email address",
    "Your pet's name":"Your pet's name",
    "Your pet's Breed":"Your pet's Breed",
    "Fur length":"Fur length",
    "Select a range":"Select a range",
    "Your pet's birthdate":"Your pet's birthdate",
    "Your pet's species":"Your pet's species",
    //ANIMAL
    "Dog":"Dog",
    "Cat":"Cat",
    "Other":"Other",
    "Rabbit/Rodent":"Rabbit/Rodent",
    "Reptile":"Reptile",
    "Bird":"Bird",
    "Fish":"Fish",
    //DROPDOWN
    "Male":"Male",
    "Female":"Female",
    "Hairless":"Hairless",
    "Short Coat":"Short Coat",
    "Medium Coat":"Medium Coat",
    "Long Coat":"Long Coat",
    "Toy (Less than 30 cm)":"Toy (Less than 30 cm)",
    "Small (30-44 cm)":"Small (30-44 cm)",
    "Medium (45-64 cm)":"Medium (45-64 cm)",
    "Large (65-89 cm)":"Large (65-89 cm)",
    "Less then 5 kg":"Less then 5 kg",
    "Rabbit":"Rabbit",
    "Genui Pig":"Genui Pig",
    "Hamster":"Hamster",
    "Rat":"Rat",
    "Gerbil":"Gerbil",
    "Dragon":"Dragon",
    "Snake":"Snake",
    "Gecko":"Gecko",
    "Turtles":"Turtles",
    //BUTTON
    "Next":"Next",
    "Cancel":"Cancel",
    "Back":"Back",
    "Register":"Register"
}

export const ptCustomerSignUp = {
    "Welcome to Petzai":"Welcome to Petzai-pt",
    "Explore and access all kinds of service":"Explore and access all kinds of service-pt",
    "fit best for your pet.":"fit best for your pet.-pt",
    "Please tell us about yourself": "Please tell us about yourself-pt",
    "Now let's talk about your pet":"Now let's talk about your pet-pt",
    "Your animal is a:":"Your animal is a:-pt",
    //FIELD
    "Name":"Nome",
    "Country":"Country-pt",
    "City":"City-pt",
    "Email":"Email-pt",
    "Phone":"Phone-pt",
    "Password":"Password-pt",
    "Confirm Password":"Confirm Password-pt",
    "Pet name":"Pet name-pt",
    "Breed":"Breed-pt",
    "Birth Date":"Birth Date-pt",
    "Gender":"Gender-pt",
    "Fur":"Fur-pt",
    "Size":"Size-pt",
    "Weight":"Weight-pt",
    "Species":"Species-pt",
    //ERROR
    "*Please Enter Name":"*Please Enter Name-pt",
    "User Name should only contain letters":"User Name should only contain letters-pt",
    "*Please Select Country":"*Please Select Country-pt",
    "*Please Select City":"*Please Select City-pt",
    "*Please Enter Email":"*Please Enter Email-pt",
    "*Your Enter Phone":"*Your Enter Phone-pt",
    "*Please Enter Password.":"*Please Enter Password.-pt",
    "Password should include at least 8 characters, one number, and one letter.":"Password should include at least 8 characters, one number, and one letter.-pt",
    "*Please Enter Confirm Password.":"*Please Enter Confirm Password.-pt",
    "*Passwords does not match.":"*Passwords does not match.-pt",
    "*Please Enter Pet's Name":"*Please Enter Pet's Name-pt",
    "*Please Enter Your Pet's Breed":"*Please Enter Your Pet's Breed-pt",
    "*Please Enter Your Pet's BirthDate":"*Please Enter Your Pet's BirthDate-pt",
    "*Please Select Gender":"*Please Select Gender-pt",
    "*Please Select Fur":"*Please Select Fur-pt",
    "*Please Select Size":"*Please Select Size-pt",
    "*Please Select Weight":"*Please Select Weight-pt",
    "*Please Select Species":"*Please Select Species-pt",
    //PLACEHOLDER
    "Your name":"Your name-pt",
    "Your country":"Your country-pt",
    "Your city":"Your city-pt",
    "Your email address":"Your email address-pt",
    "Your pet's name":"Your pet's name-pt",
    "Your pet's Breed":"Your pet's Breed-pt",
    "Fur length":"Fur length-pt",
    "Select a range":"Select a range-pt",
    "Your pet's birthdate":"Your pet's birthdate-pt",
    "Your pet's species":"Your pet's species-pt",
    //ANIMAL
    "Dog":"Dog-pt",
    "Cat":"Cat-pt",
    "Other":"Other-pt",
    "Rabbit/Rodent":"Rabbit/Rodent-pt",
    "Reptile":"Reptile-pt",
    "Bird":"Bird-pt",
    "Fish":"Fish-pt",
    //DROPDOWN
    "Male":"Male-pt",
    "Female":"Female-pt",
    "Hairless":"Hairless-pt",
    "Short Coat":"Short Coat-pt",
    "Medium Coat":"Medium Coat-pt",
    "Long Coat":"Long Coat-pt",
    "Toy (Less than 30 cm)":"Toy (Less than 30 cm)-pt",
    "Small (30-44 cm)":"Small (30-44 cm)-pt",
    "Medium (45-64 cm)":"Medium (45-64 cm)-pt",
    "Large (65-89 cm)":"Large (65-89 cm)-pt",
    "Less then 5 kg":"Less then 5 kg-pt",
    "Rabbit":"Rabbit-pt",
    "Genui Pig":"Genui Pig-pt",
    "Hamster":"Hamster-pt",
    "Rat":"Rat-pt",
    "Gerbil":"Gerbil-pt",
    "Dragon":"Dragon-pt",
    "Snake":"Snake-pt",
    "Gecko":"Gecko-pt",
    "Turtles":"Turtles-pt",
    //BUTTON
    "Next":"Next-pt",
    "Cancel":"Cancel-pt",
    "Back":"Back-pt",
    "Register":"Register-pt"
}