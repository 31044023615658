import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
interface MediaFile {
    id: number | null
    url: string;
    file?: File;
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    // Customizable Area Start
    serviceImageList: MediaFile[]
    showDelete: boolean
    onDelete: (fileToRemove: File, ImageId: number | null) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    images: any[];
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ServiceGalleryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            images: this.props.serviceImageList,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    handleDelete = (file: File, id: number | null) => {
        this.props.onDelete(file, id);
    };

    // Customizable Area End
}