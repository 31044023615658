export const enLogin = {
    "I'm a user":"I'm a user",
    "I'm a vendor":"I'm a vendor",
    "Email": "Email",
    "Your Email":"Your email",
    "Password":"Password",
    "Remember my password":"Remember my password",
    "Forgot your password? Click":"Forgot your password? Click",
    "here":"here",
    "Login": "Login",
    "Welcome back!":"Welcome back!",
    "You don't have an account yet?":"You don't have an account yet?",
    "Register":"Register",
    "*Please enter your password to proceed.":"*Please enter your password to proceed.",
    "*Please enter your email to proceed.":"*Please enter your email to proceed."
}

export const ptLogin = {
    "I'm a user":"Eu sou um usuário",
    "I'm a vendor":"Eu sou um fornecedor",
    "Email": "E-mail",
    "Your Email":"Seu e-mail",
    "Password":"Senha",
    "Remember my password":"Lembrar minha senha",
    "Forgot your password? Click":"Esqueceu sua senha? Clique",
    "here":"aqui",
    "Login": "Conecte-se",
    "Welcome back!":"Bem vindo de volta!",
    "You don't have an account yet?":"Você ainda não tem uma conta?",
    "Register":"Cadastre-se",
    "*Please enter your password to proceed.":"*Por favor, digite sua senha para prosseguir.",
    "*Please enter your email to proceed.":"*Insira seu e-mail para prosseguir."
}