import { Avatar, Box } from '@material-ui/core';
import React from 'react';
import { EditFilled } from '../assets';

type Props = {
    isEdit: boolean;
    updateAvatar: (img: Blob) => void;
}

const styles = {
    avatar: {
        height: '90px', width: '90px', marginBottom: '18px'
    },
    iconBox: {
        width: "30px",
        height: "30px",
        minWidth: "30px",
        background: "#fff",
        borderRadius: "25px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}

const CustomerAvatar = ({ isEdit, updateAvatar }: Props) => {

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if(selectedFile) {
            updateAvatar(selectedFile);
        }
    }
    
    return (
        <Box style={{ position: 'relative'}}>
            <Avatar src={localStorage.getItem("login_user_profile") || undefined} style={styles.avatar}/>
            {isEdit &&
                <label htmlFor='file-upload'>
                <Box style={{...styles.iconBox, right: "-10", bottom: 20, position: 'absolute'}}>
                    <img src={EditFilled} alt="edit_btn" />
                </Box>
                </label> 
            }
            <input data-test-id="file-input" type="file" id="file-upload" accept='image/*' style={{ display: 'none' }} onChange={handleFileChange}/>
        </Box>
    )
}

export default CustomerAvatar;