import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import EstablishmentSettingGalleryController, { Props } from "./EstablishmentSettingGalleryController.web";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { upload } from "./assets";
import { LoadingButton } from "../../../components/src/HelperComponents";
import EstablishGalleryWeb from "./Gallery/EstablishmentGallery.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Montserrat",
    }
});
// Customizable Area End

export default class EstablishmentSettingGallery extends EstablishmentSettingGalleryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderUploadButton = () => {
        return (
            <Button className="Login-Btn" component="label" data-test-id="upload-btn">
                Upload
                <input
                    style={{ display: 'none' }}
                    name="image"
                    type="file"
                    multiple
                    data-test-id="upload-file-input"
                    accept=".jpg,.png"
                    onChange={(event) => {
                        this.handleMediaSelect(event);
                    }}
                />
            </Button>
        )
    }

    renderLoader = () => {
        return (
            <CustomLoaderBox>
                <CustomLoader />
            </CustomLoaderBox>
        )
    }

    renderDefaultSection = () => {
        const { uploadedFiles, isGetListLoader, serviceImageListResponse } = this.state;
        return (
            !isGetListLoader && (
                <Box>
                    <ContentStyle>
                        <DefaultBox>
                            <Box>
                                <Typography className="header-title">
                                    Please upload here images of the general space (inside and outsides).
                                </Typography>
                            </Box>
                            <Box className="header-upload">
                                <Typography className="header-subtitle">
                                    This gallery will appear like a preview of your establishment, so the user can get to know the general appearance and conditions of the space.
                                </Typography>
                            </Box>
                        </DefaultBox>
                    </ContentStyle>
                    <Box className="upload-imageLogo">
                        {uploadedFiles.length === 0 &&
                            <Box>
                                <Box>
                                    <img src={upload} style={{ height: "97px" }} />
                                </Box>
                            </Box>
                        }
                        {uploadedFiles.length === 0 && serviceImageListResponse.length === 0 && <Box className="Upload-button">
                            <ButtonBox>{this.renderUploadButton()}</ButtonBox>
                        </Box>
                        }
                    </Box>
                </Box>
            )
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { uploadedFiles, isEditImage, isGetListLoader, serviceImageListResponse, isSaveLoading, deleteImageIds } = this.state;
        const newImageList = uploadedFiles.filter((mediaFile) => mediaFile.id === null);
        const isSaveDisable = (deleteImageIds.length > 0 || newImageList.length > 0)
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <GallerySection data-test-id="gallery-section">
                    {uploadedFiles.length === 0 && isGetListLoader && this.renderLoader()}
                    {this.renderDefaultSection()}

                    {(uploadedFiles.length > 0 || serviceImageListResponse.length > 0) && !isGetListLoader && (
                        <Box className="UploadedImage-section">
                            <Box>
                                <EstablishGalleryWeb
                                    serviceImageList={uploadedFiles}
                                    showDelete={isEditImage}
                                    onDelete={this.handleRemoveFile}
                                    data-test-id="service-gallery"
                                />
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "74px" }}>
                                <EditButtonBox>
                                    {!isEditImage && (
                                        <Button
                                            component="label"
                                            className="Login-Btn"
                                            data-test-id="edit-btn"
                                            onClick={() => this.handleEditClick()}
                                        >
                                            <Typography>Edit</Typography>
                                        </Button>
                                    )}

                                    {isEditImage && (
                                        <ButtonContainer>
                                            <CancelButton
                                                className="cancel-Btn"
                                                data-test-id="cancel-btn"
                                                onClick={() => this.handleCancelClick()}
                                            >
                                                <Typography>Cancel</Typography>
                                            </CancelButton>
                                            {this.renderUploadButton()}
                                            <SaveButton
                                                data-test-id="save-btn"
                                                onClick={() => this.handleSaveClick()}
                                                buttonText="Save"
                                                isLoading={isSaveLoading}
                                                type="submit"
                                                variant="contained"
                                                className={!isSaveDisable ? "save-btn-disable" : ""}
                                                disabled={!isSaveDisable}
                                            >
                                            </SaveButton>
                                        </ButtonContainer>
                                    )}
                                </EditButtonBox>
                            </Box>
                        </Box>
                    )}
                </GallerySection>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const GallerySection = styled(Typography)({
    width: "100%",
    minHeight: 388,
    height: "auto",
    background: "#fff",
    alignSelf: "center",
    padding: "50px 40px 46px 24px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    border: '0px 0px 0px 4px',
    boxShadow: '0px 2px 8px 0px #0000000F',
    marginBottom: 40,
    borderRadius: 16,
    "@media (max-width: 600px)": {
        padding: "50px 24px 46px 24px",
    },
    "& .upload-imageLogo": {
        display: "flex",
        alignSelf: "center",
        marginTop: 60,
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
    },
    "& .Upload-button": {
        width: "100%",
        display: "contents",
        boxSizing: "border-box",
    },
    "& .Login-Btn": {
        border: "1px solid #6F05EA",
        borderRadius: "100px",
        fontSize: "18px",
        fontWeight: 700,
        padding: "10px 0px 10px 0px",
        backgroundColor: "transparent",
        color: "#6200EA",
        gap: "10px",
        height: "37px",
        textTransform: "none",
        width: 137,
        "& .MuiTypography-body1": {
            fontSize: "18px",
            fontWeight: 700,
            "@media (max-width: 600px)": {
                fontSize: "16px",
            },
        },
        "&:hover": {
            boxShadow: "none",
            backgroundColor: "transparent",
        },
        "@media (max-width: 600px)": {
            width: 100,
            fontSize: "16px",
        },
    },
})


const ContentStyle = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: ' 100%',
    boxSizing: 'border-box',
    "& .header-upload": {
        marginTop: 10
    },
    "& .header-title": {
        fontSize: 20,
        fontWeight: 700,
        color: '#000',
        lineHeight: "24px",
        textAlign: "justify"
    },
    "& .header-subtitle": {
        fontSize: 14,
        fontWeight: 400,
        color: '#000',
        textAlign: "justify",
        lineHeight: "17px"
    },
})

const SaveButton = styled(LoadingButton)({
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "10px 0px 10px 0px",
    height: "37px",
    fontSize: "18px",
    gap: "10px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: 'none',
    width: 137,
    "&:hover": {
        boxShadow: "none",
        backgroundColor: "transparent"
    },
    "@media (max-width: 600px)": {
        width: 100,
        fontSize: "16px",
    },
})

const CancelButton = styled(Button)({
    border: '1px solid #6F05EA',
    borderRadius: "100px",
    padding: "10px 0px 10px 0px",
    height: "37px",
    fontSize: "18px",
    gap: "10px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6F05EA",
    backgroundColor: "transparent",
    width: 137,
    boxShadow: 'none',
    "& .MuiTypography-body1": {
        fontWeight: 700,
        fontSize: "18px",
        "@media (max-width: 600px)": {
            fontSize: "16px",
        },
    },
    "&:hover": {
        boxShadow: "none",
        backgroundColor: "transparent"
    },
    "@media (max-width: 600px)": {
        width: 100,
    },
})

const ButtonContainer = styled(Box)({
    display: 'flex',
    gap: '10px',
    "@media (max-width: 400px)": {
        flexDirection: "column",
    },
    "& .save-btn-disable": {
        backgroundColor: "transparent",
        borderColor: "rgba(0, 0, 0, 0.26)",
        "&:hover": {
            backgroundColor: "transparent",
        }
    }
})

const ButtonBox = styled(Box)({
    display: 'flex',
    alignSelf: 'flex-end',
    marginTop: 30,
})
const EditButtonBox = styled(Box)({
    display: 'flex',
    alignSelf: 'flex-end'
})

const DefaultBox = styled(Box)({
    width: "100%",
})

const CustomLoader = styled(CircularProgress)({
    color: "#6F05EA"
})

const CustomLoaderBox = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "50px"
})