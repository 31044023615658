Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.accountsetting='account_block/establishments';
exports.GetGallaryShow='account_block/establishments/show_gallery';
exports.httpPutMethod= "PUT";
exports.defaultCountry = "Portugal";
exports.httpDeleteMethod= "DELETE";
exports.DeleteGallary='account_block/establishments/delete_gallery';

exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
}
exports.API_CONTENT_TYPE = {
  APPLICATION_JSON: "application/json",
}

exports.ENDPOINTS = {
  GET_GALLERY: "account_block/establishments/show_gallery?id=",
  UPLOAD_GALLERY: "account_block/establishments/",
  DELETE_GALLERY: "account_block/establishments/delete_gallery/",
}
// Customizable Area End