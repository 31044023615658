import moment from "moment";
import * as Yup from "yup";

const RangeSelect = Yup.number()

export const treatsValidationSchena = Yup.object().shape({
  calm: RangeSelect,
  relaxed: RangeSelect,
  easy_going: RangeSelect,
  friendly: RangeSelect,
  gentle: RangeSelect,
  obedient: RangeSelect,
  walks: RangeSelect,
  walking_schedule: Yup.string().trim(),
  potty: RangeSelect,
  potty_schedule: Yup.string().trim(),
  meal: RangeSelect,
  meal_schedule: Yup.string().trim(),
})

export const generalValidationSchema = Yup.object().shape({
  pet_category: Yup.string()
    .oneOf([
      'cat',
      'dog',
      'rabbit/rodent',
      'fish',
      'bird',
      'reptile'
    ], 'Invalid pet category')
    .required('Please select pet category'),

  gender: Yup.string().required('Please select pet gender').nullable(),
  species: Yup.string().when('pet_category', {
    is: value => ['rabbit/rodent', 'reptile'].includes(value),
    then: Yup.string().required('Species is required').trim(),
    otherwise: Yup.string().nullable().trim()
  }),
  birthdate: Yup.string()
    .required('Birthdate is required')
    .test('is-not-future-date', '*Please enter a valid birthdate', value =>
      moment(value, 'DD/MM/YYYY').isSameOrBefore(moment())
    )
    .trim(),

  size: Yup.string().when('pet_category', {
    is: value => ['cat', 'dog'].includes(value),
    then: Yup.string().required('Size is required').trim(),
    otherwise: Yup.string().nullable().trim()
  }),

  weight: Yup.string().when('pet_category', {
    is: value => ['cat', 'dog'].includes(value),
    then: Yup.string().required('Weight is required').trim(),
    otherwise: Yup.string().nullable().trim()
  }),

  fur: Yup.string().when('pet_category', {
    is: value => ['cat', 'dog'].includes(value),
    then: Yup.string().required('Fur is required').trim(),
    otherwise: Yup.string().nullable().trim()
  }),

  breed: Yup.string().when('pet_category', {
    is: value => ['cat', 'dog'].includes(value),
    then: Yup.string().required('Breed is required').trim(),
    otherwise: Yup.string().nullable().trim()
  }),
});

export const medicalValidationSchema = Yup.object().shape({
  medical_conditions: Yup.string().trim(),
  allergies: Yup.string().trim(),
  usual_medication: Yup.string().trim(),
  last_health_check_up: Yup.string()
    .test("is-not-future-date", "*Please enter last health checkup", (value) => {
      if(!value) return true;
      return moment(value, "DD/MM/YYYY").isSameOrBefore(moment());
    }).trim(),
  cheap: Yup.boolean(),
  microchip: Yup.string().when('cheap', {
    is: true,
    then: Yup.string().trim(),
    otherwise: Yup.string().notRequired(),
  }),
  vaccines_up_to_date: Yup.boolean(),
  sterilized: Yup.boolean(),
  hospital: Yup.string().trim(),
  hospital_number: Yup.string()
    .max(12, "*Enter valid phone number")
    .trim(),
  hospital_address: Yup.string().trim(),
  doctor_name: Yup.string().trim(),
  company: Yup.string().trim(),
  policy_number: Yup.string().trim(),
  primary_name: Yup.string().trim(),
  primary_contact: Yup.string()
    .max(12, "*Enter valid phone number")
    .trim(),
  secondary_name: Yup.string().trim(),
  secondary_contact: Yup.string()
    .max(12, "*Enter valid phone number")
    .trim(),
});

export const personalValidationShema = Yup.object().shape({
  first_name: Yup.string().required('*Please enter first name').trim(),
  last_name: Yup.string().required('*Please enter last name').trim(),
  full_phone_number: Yup.string()
    .required('*Please enter phone number')
    .max(16, "*Enter valid phone number")
    .trim(),
  date_of_birth: Yup.string()
    .test("is-not-future-date", "*Please enter birthdate", (value) =>
      moment(value, "DD/MM/YYYY").isSameOrBefore(moment())
    )
    .trim(),
  nationality: Yup.string().required('*Please enter nationality').trim(),
  city: Yup.string().required('*Please enter city').trim(),
  country: Yup.string().required('*Please enter country').trim(),
  language: Yup.string().required('*Please select language').trim(),
  zipcode: Yup.string().required('*Please enter zipcode').trim(),
  address: Yup.string().required('*Please enter address').trim()
});

export const resheduleValidationHotel = Yup.object().shape({
  start_date: Yup.string()
    .required('*Please enter new start date')
    .trim()
    .test('is-valid-start', '*Start date cannot be after end date', function (value) {
      const { end_date } = this.parent;
      if (!value || !end_date) return true; 
      const startDate = moment(value, 'DD/MM/YYYY');
      const endDate = moment(end_date, 'DD/MM/YYYY');
      return startDate.isValid() && endDate.isValid() && startDate.isSameOrBefore(endDate);
    }),

  end_date: Yup.string()
    .required('*Please enter new end date')
    .trim()
    .test('is-valid-end', '*End date cannot be before start date', function (value) {
      const { start_date } = this.parent;
      if (!value || !start_date) return true; 
      const endDate = moment(value, 'DD/MM/YYYY');
      const startDate = moment(start_date, 'DD/MM/YYYY');
      return endDate.isValid() && startDate.isValid() && endDate.isSameOrAfter(startDate);
    }),
})

export const resheduleValidationDogWalking = Yup.object().shape({
  start_date: Yup.string().required('*Please enter new start date').trim(),
  shift: Yup.string().required('*Please enter time').trim(),
})

export const resheduleValidationGeneral = Yup.object().shape({
  start_date: Yup.string().required('*Please enter new start date').trim(),
}) 