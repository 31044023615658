import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { female, macImage, male, phoneIcon, windowsImage } from "./assets";
import { FormikErrors, FormikTouched } from "formik";
import { getCountryCode } from "countries-list";
import moment from "moment";
import { PasswordFormValues } from "./components/ResetPasswordModal";
import { EmailFormValues } from "./components/ResetEmailModal";
import { getStorageData} from "../../../framework/src/Utilities";
import { Views } from "react-big-calendar";
import { getAppointmentParams, getLatestRequest } from "../../../components/src/helpers";
import { IGetPetResponse, IPersonalInfo, IPetBreed, PersonalSenitizeObject, ResheduleRequestFields } from "./types";
import { ICreatedPaymentCard, IPaymentCard } from "../../../components/src/interfaces.web";

interface IPaymentCardResponse {
  payment_methods: IPaymentCard[]
}
interface ICardError {
  error: string;
}
interface ICardMessage {
  message: string;
}
interface IStripeCard {
  "errors": [
      { "stripe": string }
  ]
}

type ICreateAddCardResponse = ICreatedPaymentCard | ICardMessage | ICardError | IStripeCard
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type Keys = keyof typeof Views;

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface IMenuItem {
  title: string;
  icon: string;
}

interface ICountryModel {
  label: string;
  value: string;
}
export interface IPet {
  pet_category: string;
  id?: number;
  name?: string;
  gender: string;
  breed: string;
  birthdate: string;
  weight: string;
  size: string;
  account_id: number;
  fur: string;
  species: string;
  created_at?: string;
  updated_at?: string;
  pet_photo: null | string;
  medical?: IMedicalForm;
  treats?: ITreatsForm;
  photo_blob?: File;
}

type Rating = "0" | "1" | "2";

type AppointmentParams = {
  filter_type?: string;
  start_date?: string;
  end_date?: string;
}

interface ITreatsFormResponse {
  calm: Rating;
  relaxed: Rating;
  easy_going: Rating;
  friendly: Rating;
  gentle: Rating;
  obedient: Rating;
  walks: number;
  walking_schedule: string;
  potty: number;
  potty_schedule: string;
  meal: number;
  meal_schedule: string;
}

interface APIModal {
  body?: object;
  formData?: object;
  token?: string;
  contentType?: string;
  method: string;
  endPoint: string;
}

export interface IMedicalForm {
  id?: number;
  medical_conditions: string;
  allergies: string;
  usual_medication: string;
  last_health_check_up: string;
  cheap: boolean;
  microchip: string;
  vaccines_up_to_date: boolean;
  sterilized: boolean;
  country_code?: string;
  hospital: string;
  hospital_address: string;
  hospital_number: string;
  doctor_name: string;
  company: string;
  policy_number: string;
  primary_name: string;
  primary_contact: string;
  secondary_name: string;
  secondary_contact: string;
}

export interface ICardData {
  id: string;
  brand: string;
  exp_month: string;
  exp_year: string;
  last4: string;
  name: string;
}

interface ITreatsForm {
  id?: number;
  calm: string | undefined;
  relaxed: string | undefined;
  easy_going: string | undefined;
  friendly: string | undefined;
  gentle: string | undefined;
  obedient: string | undefined;
  walks: number | undefined;
  walking_schedule: string;
  potty: number | undefined;
  potty_schedule: string;
  meal: number | undefined;
  meal_schedule: string;
}



export interface ILoggedSession {
  id: number,
  device_name: string,
  device_id: string,
  last_active: string,
  location: string,
  created_at: string,
  updated_at: string,
  name: string
}

export type CardFields = {
  number: string,
  valid_till: string,
  name: string
}

export type Request = {
  id: number;
  request_type: string;
  status: string;
  start_date: string;
  end_date: string;
  shift: string;
  created_by: "customer" | "vendor"
}

export interface Shedule {
  id: number,
  shift: string,
  start_date: string;
  end_date: string;
  price: string;
  total_price: string;
  medication: string;
  transportation: string;
  repeat_weekly?: boolean;
  medication_request: boolean;
  transportation_request: boolean;
  sub_service: {
    id: number;
    title: string;
    location: string;
    images: string[];
  };
  service: {
    id: number;
    service_type: string;
  };
  sub_services_price: {
    id: number;
    price: number;
  };
  service_provider: {
    id: number;
    name: string;
    email: string;
  };
  customer: {
    id: number;
    name: string;
    email: string;
  };
  requests: {
    request_details: Request[];
  };
  pet: {
    data: {
      id: number;
      attributes: IPet;
    }
  }
}

const defaultMedical = {
  medical_conditions: "",
  allergies: "",
  usual_medication: "",
  last_health_check_up: "",
  cheap: false,
  microchip: "",
  vaccines_up_to_date: false,
  sterilized: false,
  hospital: "",
  hospital_address: "",
  hospital_number: "351",
  doctor_name: "",
  company: "",
  policy_number: "",
  primary_name: "",
  primary_contact: "351",
  secondary_name: "",
  secondary_contact: "351",
  country_code: "351",
};

const defaultTreats = {
  calm: undefined,
  relaxed: undefined,
  easy_going: undefined,
  friendly: undefined,
  gentle: undefined,
  obedient: undefined,
  walks: 0,
  walking_schedule: "",
  potty: 0,
  potty_schedule: "",
  meal: 0,
  meal_schedule: "",
};

const defaultPersonal = {
  first_name: "",
  last_name: "",
  phone_number: "",
  full_phone_number: "",
  date_of_birth: "",
  nationality: "",
  city: "",
  country: "",
  language: "portuguese",
  zipcode: "",
  address: "",
  country_code: ""
}

const styles = {
  filledLabel: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#6F05EA",
    fontFamily: "Montserrat",
    lineHeight: '17px'
  },
  secondaryLabel: {
    color: '#777777',
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Montserrat",
    lineHeight: '17px'
  },
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  fields: IField[];
  profileImageUrl: string;
  token: string;
  userId: string;
  currentMenuItem: string | null;
  petsList: IPet[];
  shedules: Shedule[];
  selectedPet: IPet | null;
  currentTab: number;
  countries: {
    label: string;
    value: string | number | undefined;
  }[];
  cities: {
    label: string;
    value: string | number | undefined;
  }[];
  sessions: ILoggedSession[];
  currentSettingsTab: number;
  isoCountryName: string | boolean;
  treats: ITreatsForm;
  medical: IMedicalForm;
  personal: IPersonalInfo;
  isEditMedical: boolean;
  isEditTreats: boolean;
  isEditGeneral: boolean;
  isEditPersonal: boolean;
  isEditRequestChange: boolean;
  isLoadingPets: boolean;
  isLoadingPersonal: boolean;
  isLoadingMedicalForm: boolean;
  isLoadingTreatsForm: boolean;
  isLoadingGeneralForm: boolean;
  isLoadingChangeRequest: boolean;
  isLoadingCreatePet: boolean;
  isOpenLanguageModal: boolean;
  isOpenResetModal: boolean;
  isOpenResetEmailModal: boolean;
  isOpenEndSession: boolean;
  isOpenDeleteModal: boolean;
  isOpenCancelationModal: boolean;
  isOpenChangeModal: boolean;
  isLoadingCancelation: boolean;
  isLoadingChangeStatus: boolean;
  newPassword: string;
  settingsError: string;
  isLoadingApp: boolean;
  apiError: string;
  tokenError: boolean;
  endSessionData: ILoggedSession | null;
  selectedEvent: Shedule | null;
  calendarDate: Date;
  calendarView: (typeof Views)[Keys];
  dogBreedList: IPetBreed[],
  catBreedList: IPetBreed[],
  isLoadingPhoto: boolean;
  // PAYMENT TAB - CARDS
  addCardModal: boolean,
  addCardLoading: boolean,
  deleteCardLoading: boolean,
  setDefaultCardLoading: boolean,
  setDefaultAddCard: boolean,
  addCardModalError: string,
  paymentCardList: IPaymentCard[],
  paymentCardListLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiPetListCallId: string = "";
  apiCreatePetCallId: string = "";
  apiCreateMedicalsCallId: string = "";
  apiCreateTreatsCallId: string = "";
  apiUpdateMedicalCallId: string = "";
  apiUpdateTreatsCallId: string = "";
  apiUpdateGeneralCallId: string = "";
  apiDeletePetCallId: string = "";
  apiRemovePhoto: string = "";
  apiGetPetMedical: string = "";
  apiGetPetTreats: string = "";
  apiGetSessionsCallId: string = "";
  apiGetProfileData: string = "";
  apiUpdatePasswordCallId: string = "";
  apiUpdateEmailCallId: string = "";
  apiDeleteAccountCallId: string = "";
  apiUpdateAccountCallId: string = "";
  apiDeleteSessionCallId: string = "";
  apiGetAppointmentsCallId: string = "";
  apiCancelationCallId: string = "";
  apiManageRequestStatus: string = "";
  apiMakeResheduleCallId: string = "";
  getDogBreedApiCallId: string = "";
  getCatBreedApiCallId: string = "";
  // CARDS
  getCardListApiCallId: string = "";
  addCardApiCallId: string = "";
  setDefaultCardApiCallId: string = "";
  deleteCardApiCallId: string = "";
  apiUpdateProfileImageCallId: string = "";
  catDog = ['Dog', 'Cat'];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profileImageUrl: "",
      fields: [],
      token: "",
      userId: "",
      currentMenuItem: localStorage.getItem("selectedValueCustomer") !== null ? localStorage.getItem("selectedValueCustomer")  : "0",
      petsList: [],
      selectedPet: null,
      currentTab: 0,
      shedules: [],
      currentSettingsTab: 0,
      isoCountryName: "pt",
      countries: [],
      cities: [],
      sessions: [],
      treats: defaultTreats,
      medical: defaultMedical,
      personal: defaultPersonal,
      isEditMedical: false,
      isEditTreats: false,
      isEditGeneral: false,
      isLoadingPets: false,
      isEditPersonal: false,
      isEditRequestChange: true,
      isLoadingPersonal: false,
      isLoadingMedicalForm: false,
      isLoadingTreatsForm: false,
      isLoadingGeneralForm: false,
      isLoadingChangeRequest: false,
      isOpenLanguageModal: false,
      isOpenResetModal: false,
      isOpenResetEmailModal: false,
      isOpenCancelationModal: false,
      isLoadingCreatePet: false,
      isOpenChangeModal: false,
      isOpenDeleteModal: false,
      isOpenEndSession: false,
      newPassword: "",
      settingsError: "",
      isLoadingApp: true,
      apiError: '',
      tokenError: false,
      endSessionData: null,
      selectedEvent: null,
      calendarDate: moment().toDate(),
      calendarView: Views.WEEK,
      isLoadingCancelation: false,
      isLoadingChangeStatus: false,
      dogBreedList: [],
      catBreedList: [],
      isLoadingPhoto: false,
      // PAYMENT TAB - CARDS
      addCardModal: false,
      addCardLoading: false,
      deleteCardLoading: false,
      setDefaultCardLoading: false,
      setDefaultAddCard: true,
      addCardModalError: "",
      paymentCardList: [],
      paymentCardListLoading: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.doEmailLogin();
    this.getCountryListData();
    this.getDogBreedList()
    this.getCatBreedList()
    const headerSelectedValue = await getStorageData("selectedValueCustomer") ?  await getStorageData("selectedValueCustomer") : "0";
    this.setState({currentMenuItem:headerSelectedValue === "4" ? "0" : headerSelectedValue });
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleSessionCheck(responseJson);
      switch (apiRequestCallId) {
        case this.apiPetListCallId: {
          this.handleGetPetListResponse(responseJson, errorJson);
          break;
        }

        case this.apiCreateMedicalsCallId: {
          this.handleCreateMedicalResponse(responseJson, errorJson);
          break;
        }

        case this.apiUpdateMedicalCallId: {
          this.handleUpdateMedicalResponse(responseJson, errorJson);
          break;
        }

        case this.apiCreateTreatsCallId: {
          this.handleCreateTreatsResponse(responseJson, errorJson);
          break;
        }

        case this.apiUpdateTreatsCallId: {
          this.handleUpdateTratsResponse(responseJson, errorJson);
          break;
        }

        case this.apiUpdateGeneralCallId: {
          this.handleUpdatePetResponse(responseJson, errorJson);
          break;
        }

        case this.apiCreatePetCallId: {
          this.handleCreatePetResponse(responseJson, errorJson);
          break;
        }

        case this.apiDeletePetCallId: {
          this.handleDeletePetResponse(responseJson, errorJson);
          break;
        }

        case this.apiRemovePhoto: {
          this.handleRemovePetPhotoResponse(responseJson, errorJson);
          break;
        }
              
        case this.apiGetPetMedical: {
          this.handleGetPetMedicalsResponse(responseJson, errorJson);
          break;
        }

        case this.apiGetPetTreats: {
          this.handleGetPetTreatsResponse(responseJson, errorJson);
          break;
        }

        case this.apiGetSessionsCallId: {
          this.handleGetSessionsResponse(responseJson, errorJson);
          break;
        }

        case this.apiUpdatePasswordCallId: {
          this.handleUpdatePasswordResponse(responseJson, errorJson);
          break;
        }

        case this.apiUpdateEmailCallId: {
          this.handleEmailUpdateResponse(responseJson, errorJson);
          break;
        }

        case this.apiDeleteAccountCallId: {
          this.handleDeleteAccountResponse(responseJson, errorJson);
          break;
        }

        case this.apiGetProfileData: {
          this.handleGetAccountResponse(responseJson, errorJson);
          break;
        }

        case this.apiUpdateAccountCallId: {
          this.handleUpdateAccountResponse(responseJson, errorJson);
          break;
        }

        case this.apiUpdateProfileImageCallId: {
          this.handleUpdateAvatarResponse(responseJson);
          break;
        }

        // CARDS APIS STARTS
        case this.getCardListApiCallId: {
          this.getCardListApiResp(responseJson);
          break;
        }
        case this.addCardApiCallId: {
          this.addCardApiResp(responseJson);
          break;
        }
        case this.setDefaultCardApiCallId: {
          this.setDefaultCardApiResp(responseJson);
          break;
        }
        case this.deleteCardApiCallId: {
          this.deleteCardApiResp(responseJson);
          break;
        }
        // CARDS APIS ENDS

        case this.apiDeleteSessionCallId: {
          this.handleDeleteSession(responseJson, errorJson);
          break;
        }

        case this.apiGetAppointmentsCallId: {
          this.handleAppointmentsList(responseJson, errorJson);
          break;
        }

        case this.apiCancelationCallId: {
          this.handleCancelationRequest(responseJson);
          break;
        }

        case this.apiManageRequestStatus: {
          this.handleChangeRequestStatus(responseJson);
          break;
        }

        case this.apiMakeResheduleCallId: {
          this.handleMakeResheduleResponse(responseJson);
          break;
        }

        case this.getDogBreedApiCallId: {
          this.getDogBreedApiResp(responseJson);
          break;
        }

        case this.getCatBreedApiCallId: {
          this.getCatBreedApiResp(responseJson);
          break;
        }

        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getDogBreedList = async () => {
    this.getDogBreedApiCallId = await this.apiCall({
      endPoint: `${configJSON.breedList}?animal_breed[animal_type]=Dog`,
      method: configJSON.httpGetMethod,
    })

  }

  getCatBreedList = async () => {
    this.getCatBreedApiCallId = await this.apiCall({
      endPoint: `${configJSON.breedList}?animal_breed[animal_type]=Cat`,
      method: configJSON.httpGetMethod,
    })
  }

  handleUpdateAvatarResponse = (response: any) => {
    if(response && response.data?.attributes?.vendor_image) {
      localStorage.setItem("login_user_profile", response.data.attributes.vendor_image);
      this.forceUpdate();
    }
  }

  getDogBreedApiResp = (responseJson: IGetPetResponse) => {
    if (responseJson && responseJson.breeds && responseJson.breeds.length ) {
      this.setState({ dogBreedList: responseJson.breeds })
    } else {
      this.setState({ dogBreedList: [] })
    }
  }

  getCatBreedApiResp = (responseJson: IGetPetResponse) => {
    if (responseJson && responseJson.breeds && responseJson.breeds.length ) {
      this.setState({ catBreedList: responseJson.breeds })
    } else {
      this.setState({ catBreedList: [] })
    }
  }

  getBreedList = (category: string) => {
    const { dogBreedList, catBreedList } = this.state
    if (category == "dog") {
      return dogBreedList.map((option) => option.breed)
    }
    if (category == "cat") {
      return catBreedList.map((option) => option.breed)
    }
    return []
  }

  handleMakeResheduleResponse = (responseJson: any) => {
    this.setState({ isLoadingChangeRequest: false, isOpenChangeModal: false })
    if(responseJson && responseJson.meta && responseJson.meta.message === 'Request created successfully') {
      this.getAppointmentsList(this.state.calendarView, this.state.calendarDate);
    } else {
      this.setState({ apiError: 'Failed make reshedule request' });
    }
  }

  handleCancelationRequest = (responseJson: any) => {
    this.setState({ isLoadingCancelation: false, isOpenCancelationModal: false })
    if(responseJson && responseJson.meta && 
      (responseJson.meta.message === 'Request created successfully' || responseJson.meta === "Requests created successfully")) {
      this.getAppointmentsList(this.state.calendarView, this.state.calendarDate);
    } else {
      this.setState({ apiError: 'Failed make cancelation request' });
    }
  }

  handleChangeRequestStatus = (responseJson: any) => {
    this.setState({ isLoadingChangeStatus: false });
    if(responseJson && responseJson.meta && responseJson.meta.message === 'Request updated successfully') {
      this.getAppointmentsList(this.state.calendarView, this.state.calendarDate);
    }else {
      this.setState({ apiError: 'Failed manage request state' });
    }
  }

  handleOpenDeleteModal = (device: ILoggedSession) => {
    this.setState({ isOpenEndSession: true, endSessionData: device })
  }

  handleCloseDeleteModal = () => {
    this.setState({ isOpenEndSession: false, endSessionData: null })
  }

  handleSessionCheck = (responseJson: any) => {
    if(responseJson && responseJson.errors) {
      if(Array.isArray(responseJson.errors)) {
        if(responseJson.errors[0].token) {
          this.setState({ tokenError: true })
        }
      }
    }
  }

  handleDeleteSession = (responseJson: any, errorJson: any) => {
    if(responseJson && responseJson.message === 'Session ended successfully') {
      this.handleCloseDeleteModal();
      this.getLoggedSessions();
    } else {
      this.setState({ apiError: 'Failed delete session' });
    }
  }

  handleAppointmentsList = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        const shedules = responseJson.data.map(
          (shedule: { attributes: Shedule }) => shedule.attributes
        );
        if(this.state.selectedEvent) {
          const shedule = shedules.find((shedule: Shedule) => shedule.id === this.state.selectedEvent?.id);
          if(shedule) {
            this.setState({ selectedEvent: shedule });
          }
        }
        this.setState({ shedules });
      },
      onFail: () => {
        this.setState({ apiError: 'Failed get shedule list' });
      }
    });
  }


  handleGetPetListResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        const petsList = responseJson.data.map(
          (pet: { attributes: IPet }) => pet.attributes
        );
        this.setState({ petsList });
      },
      onFail: () => {
        this.setState({ apiError: 'Failed load pet list'});
      }
    });
  }

  handleCreateMedicalResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        window.scrollTo(0, 0);
        this.setState({
          isLoadingMedicalForm: false,
          isEditMedical: false,
        });
        this.updatePetListData(responseJson.data.attributes, "medical", true);
      },
      onFail: () => {
        this.setState({ isLoadingMedicalForm: false, apiError: 'Failed create pet medical' });
      },
    });
  }

  handleUpdateMedicalResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        window.scrollTo(0, 0);
        this.setState({
          isLoadingMedicalForm: false,
          isEditMedical: false,
        });
        this.updatePetListData(responseJson.data.attributes, "medical");
      },
      onFail: () => {
        this.setState({ isLoadingMedicalForm: false, apiError: 'Failed update pet medical' });
      },
    });
  }

  handleCreateTreatsResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        window.scrollTo(0, 0);
        this.setState({
          isLoadingTreatsForm: false,
          isEditTreats: false,
        });
        this.updatePetListData(responseJson.data.attributes, "treats");
      },
      onFail: () => {
        this.setState({ isLoadingTreatsForm: false, apiError: 'Failed create pet treats' });
      },
    });
  }

  handleUpdateTratsResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        window.scrollTo(0, 0);
        this.setState({
          isLoadingTreatsForm: false,
          isEditTreats: false,
        });
        this.updatePetListData(responseJson.data.attributes, "treats");
      },
      onFail: () => {
        this.setState({ isLoadingTreatsForm: false, apiError: 'Failed update pet treats' });
      },
    });
  }

  handleCreatePetResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        this.setState({
          isLoadingGeneralForm: false,
          isEditGeneral: false,
          isLoadingCreatePet: false
        });
        window.scrollTo(0, 0);
        this.handleAddPetData(responseJson.data.attributes);
      },
      onFail: () => {
        this.setState({ isLoadingTreatsForm: false, apiError: 'Failed create pet general' });
      },
    });
  }

  handleUpdatePetResponse = (responseJson: any, errorJson: any) => {
    if(responseJson){
      this.setState({
        isLoadingPhoto: false,
      });
    }
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        window.scrollTo(0, 0);
        this.setState({
          isLoadingGeneralForm: false,
          isEditGeneral: false,
        });
        this.handleUpdatePetData(responseJson.data.attributes);
      },
      onFail: () => {
        this.setState({ isLoadingTreatsForm: false, apiError: 'Failed update pet general' });
      },
    });
  }

  handleDeletePetResponse = (responseJson: any, errorJson: any) => {
    if(responseJson && responseJson.message) {
      window.scrollTo(0, 0);
      this.setState({
        isEditGeneral: false,
      });
      this.handleUpdatePets();
    } else {
      this.setState({ apiError: 'Failed update pet general' })
    }
  }

  handleRemovePetPhotoResponse = (responseJson: any, errorJson: any) => {
    if(responseJson && responseJson.message) {
      window.scrollTo(0, 0);
      this.setState({
        isLoadingPhoto: false,
      });
    } else {
      this.setState({ apiError: 'Failed update pet general' })
    }
  }

  handleGetPetMedicalsResponse = (responseJson: any, errorJson: any) => {
    if(responseJson.data) {
      this.updatePetListData(responseJson.data.attributes, "medical");
    } else if(responseJson.data === null) {
      this.setState({ isLoadingTreatsForm: false });
    } else {
      this.setState({ isLoadingTreatsForm: false, apiError: 'Failed get pet medical' });
    }
  }

  handleGetPetTreatsResponse = (responseJson: any, errorJson: any) => {
    if(responseJson.data) {
      this.updatePetListData(responseJson.data.attributes, "treats");
    } else if(responseJson.data === null) {
      this.setState({ isLoadingTreatsForm: false });
    } else {
      this.setState({ isLoadingTreatsForm: false, apiError: 'Failed get pet treats' });
    }
  }

  handleGetSessionsResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        const resList = responseJson.data.map((session: {attributes: ILoggedSession}) => session.attributes);
        this.setState({ sessions: resList });
      },
      onFail: () => {
        this.setState({ apiError: 'Failed get sessions'})
      },
    });
  }


  handleUpdatePasswordResponse = (responseJson: any, errorJson: any) => {
    if(responseJson && responseJson.message && responseJson.message === "Password updated successfully") {
      localStorage.setItem('login_password', this.state.newPassword);
      this.setState({ isOpenResetModal: false })
    } else {
      if(errorJson && errorJson.errors) {
        this.setState({ settingsError: errorJson.errors })
      } else {
        this.setState({ apiError: 'Failed update password'})
      }
    }
  }

  handleEmailUpdateResponse = (responseJson: any, errorJson: any) => {
    if(responseJson && responseJson.data) {
      this.setState({ isOpenResetEmailModal: false })
    } else {
      if(errorJson && errorJson.errors) {
        this.setState({ settingsError: errorJson.errors })
      } else {
        this.setState({ apiError: 'Failed update email'})
      }
    }
  }

  clearStorageAndRedicrect = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.goToLogin();
  }

  handleDeleteAccountResponse = (responseJson: any, errorJson: any) => {
    if(responseJson && responseJson.message === 'Account has been Successfully Deleted'){
      this.clearStorageAndRedicrect();
    } else {
      this.setState({ apiError: 'Failed delete account'})
    }
  }



  handleBuildPersonalData = (data: PersonalSenitizeObject): IPersonalInfo => {
    const senitizeObject: IPersonalInfo = {} as IPersonalInfo;
    for(const key in data) {
      if(data[key as keyof IPersonalInfo] === null) {
        senitizeObject[key as keyof IPersonalInfo]
      } else {
        senitizeObject[key as keyof IPersonalInfo] = key === 'date_of_birth'
          ? moment(data[key as keyof IPersonalInfo]).format("DD/MM/YYYY")
          : data[key as keyof IPersonalInfo]!;
      }
    }

    return senitizeObject;
  }

  handleGetAccountResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        this.handleCountryNameChange(responseJson.data.attributes.country);
        this.setState({ personal: {
            id: responseJson.data.id,
          ...(this.handleBuildPersonalData({...responseJson.data.attributes }))
          }
        });
      },
      onFail: () => {
        this.setState({ apiError: 'Failed get personal data'})
      },
    });
  }

  handleUpdateAccountResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        window.scrollTo(0, 0);
        this.setState({
          personal: {
            ...(this.handleBuildPersonalData({...responseJson.data.attributes })),
            id: responseJson.data.id,
          },
          isEditPersonal: false
        });
      },
      onFail: () => {
        this.setState({ apiError: 'Failed update personal data'})
      },
    });
  }

  handleGetInitialData = () => {
    if(!this.state.sessions.length) {
      this.getLoggedSessions();
    }
    if(!this.state.personal.id) {
      this.getPersonalData();
    }
    if (!this.state.paymentCardList.length) {
      this.getCardList();
    }
    if (!this.state.petsList.length) {
      this.getPetsList();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if(this.state.apiError) {
      if(this.state.tokenError) {
        this.clearStorageAndRedicrect();
      } else {
        this.showAlert("API error", this.state.apiError);
      }
    }
    if(prevState.selectedPet?.id !== this.state.selectedPet?.id) {
      if(this.state.selectedPet) {
        if(!this.catDog.includes(this.state.selectedPet.pet_category)) {
          this.setState({ currentTab: 0, medical: defaultMedical, treats: defaultTreats });
        }
        if(this.state.selectedPet.id) {
          this.getPetInfo();
        }
      }
    }
    if( this.state.token && (prevState.token !== this.state.token)) {
      this.handleGetInitialData();
    }
    this.handleCleanEdits(prevState);
  }

  handleCleanEdits = (presvState: S) => {
    if(presvState.currentTab !== this.state.currentTab) {
      switch (this.state.currentTab) {
        case 0:
          this.setState({ isEditMedical: false, isEditTreats: false })
          break;
        case 1:
          this.setState({ isEditGeneral: false, isEditTreats: false })
          break;
        case 2:
          this.setState({ isEditGeneral: false, isEditMedical: false })
          break;
        default:
          break;
      }
    }
  }

  defaultInputProps = {
    style: { marginBottom: "35px", height: "34px" },
  };

  handleChangeDate = (date: Date) => {
    this.setState({ calendarDate: date });
  }

  handleSetView = (view: (typeof Views)[Keys]) => {
    this.setState({ calendarView: view })
  }

  handleRequestCancelation = async (recurring_type?: string) => {
    if(this.state.selectedEvent) {
      this.setState({ isLoadingCancelation: true })
      const formData = new FormData();
      formData.append(
        "request_type",
        "Cancellation Request"
      );
      formData.append(
        "schedules_id",
        this.state.selectedEvent.id.toString()
      )
      if(recurring_type && recurring_type === 'all') {
        formData.append("recurrence_booking", "true")
      }
      this.apiCancelationCallId = await this.apiCall({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.getRequestListEndpoint,
        token: this.state.token,
        formData: formData,
      });
    }
  }

  handleRequestChange = async (values: ResheduleRequestFields) => {
    if(this.state.selectedEvent) {
      this.setState({ isLoadingChangeRequest: true });
      const formData = new FormData();
      formData.append(
        "request_type",
        "Reschedule Request"
      );
      formData.append(
        "schedules_id",
        this.state.selectedEvent.id.toString()
      )
      formData.append(
        "start_date",
        values.start_date
      )
      if(values.end_date) {
        formData.append(
          "end_date",
          values.end_date
        )
      }
      if(values.shift) {
        formData.append(
          "shift",
          values.shift
        )
      }
      this.apiMakeResheduleCallId = await this.apiCall({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.getRequestListEndpoint,
        token: this.state.token,
        formData: formData,
      });
    }
  }

  handleProceedRequestStatus = async (type: 'rejected' | 'accepted') => {
    if(this.state.selectedEvent) {
      this.setState({ isLoadingChangeStatus: true });
      const formData = new FormData();
      formData.append(
        "status",
        type
      );
      const reqId = getLatestRequest(this.state.selectedEvent.requests.request_details);
      this.apiManageRequestStatus = await this.apiCall({
        method: configJSON.httpPatchMethod,
        endPoint: `${configJSON.getRequestListEndpoint}/${reqId?.id}`,
        token: this.state.token,
        formData: formData,
      });
    }
  }

  handleAddPet = () => {
    window.scrollTo(0, 0);
    this.setState({ selectedPet: {
        ...configJSON.defaultPetData,
        account_id: Number(this.state.userId)
      },
      isEditGeneral: true,
    })
  }

  navigateToPage = (targetPage: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), targetPage);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  goToForgotPassword = () => {
    this.navigateToPage("ForgotPassword");
  };

  goToLogin = async () => {
    this.navigateToPage("EmailAccountLoginBlock");
  }

  handleEndSession = async (dId: string) => {
    const formData = new FormData();
    formData.append('sessions[device_id]', dId);
    this.apiDeleteSessionCallId = await this.apiCall({
      method: configJSON.httpDeleteMethod,
      endPoint: configJSON.deleteSessionEndPoint,
      token: this.state.token,
      formData: formData,
    });
  }

  updatePassword = async (values: PasswordFormValues) => {
    const formData = new FormData();
    formData.append('current_password', values.current_password);
    formData.append('password', values.password);
    formData.append('password_confirmation', values.password_confirmation);
    this.setState({ newPassword: values.password });
    this.apiUpdatePasswordCallId = await this.apiCall({
      method: configJSON.httpPostMethod,
      endPoint: configJSON.updatePassword,
      token: this.state.token,
      formData: formData,
    });
  }

  updateEmail = async (values: EmailFormValues) => {
    const formData = new FormData();
    formData.append('email', values.email);
    formData.append('confirm_email', values.confirm_email);
    formData.append('current_password', values.current_password);
    this.apiUpdateEmailCallId = await this.apiCall({
      method: configJSON.httpPostMethod,
      endPoint: configJSON.updateEmail,
      token: this.state.token,
      formData: formData,
    });
  }

  deleteAccount = async () => {
    this.apiDeleteAccountCallId = await this.apiCall({
      method: configJSON.httpDeleteMethod,
      endPoint: `${configJSON.deleteAccount}/${localStorage.getItem('login_user_id') || ''}`,
      token: this.state.token,
    });
  }


  getPersonalData = async () => {
    this.apiGetProfileData = await this.apiCall({
      contentType: configJSON.httpContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getAccountData}${this.state.userId}`,
      token: this.state.token,
    })
  }

  getLoggedSessions = async () => {
    this.apiGetSessionsCallId = await this.apiCall({
      contentType: configJSON.httpContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getLoggedSessions,
      token: this.state.token,
    });
  }

  getPetInfo = async () => {
    if(this.state.selectedPet) {
      this.apiGetPetMedical = await this.apiCall({
        method: configJSON.httpGetMethod,
        endPoint: `${configJSON.getPetMedical}?pet_medical[animal_type_id]=${this.state.selectedPet.id}`,
        token: this.state.token,
      });
      this.apiGetPetTreats = await this.apiCall({
        method: configJSON.httpGetMethod,
        endPoint: `${configJSON.getPetTreats}?traits[animal_type_id]=${this.state.selectedPet.id}`,
        token: this.state.token,
      });
    }
  }


  handleUpdatePets = () => {
    if(this.state.selectedPet) {
      if(this.state.petsList.length >= 2) {
        const updatedPetsList = this.state.petsList.filter(pet => pet.id !== this.state.selectedPet?.id);
        this.setState({ petsList: updatedPetsList, selectedPet: updatedPetsList[0] });
      } else {
        this.setState({ selectedPet: null, petsList: [] });
      }
    }
  }

  handleAddPetData = (data: IPet) => {
    this.setState(prevState => ({
      petsList: [...prevState.petsList, data],
      selectedPet: {
        ...prevState.selectedPet,
        ...data
      },
      currentTab: this.catDog.includes(data.pet_category) ? 1 : 0,
      isEditMedical: this.catDog.includes(data.pet_category) ? true : false
    }))
  }

  handleUpdatePetData = (data: IPet) => {
    this.setState(prevState => ({
      petsList: prevState.petsList.map(pet =>
        (pet.id === data.id) ? { ...pet, ...data } : pet
      ),
      selectedPet: {
        ...prevState.selectedPet,
        ...data
      }
    }));
    this.forceUpdate();
  }

  handleResponseMessage<T>(input: {
    responseJson: {
      data?: T;
      errors?: T;
      error: T;
    };
    errorJson?: T;
    onSuccess: () => void;
    onFail: () => void;
  }) {
    const { responseJson, onSuccess, onFail, errorJson } = input;
    if (
      responseJson &&
      !responseJson.error &&
      !responseJson.errors &&
      responseJson.data
    ) {
      onSuccess();
    }

    if (
      responseJson?.error ||
      errorJson ||
      responseJson.errors ||
      !responseJson.data
    ) {
      onFail();
    }
  }

  transformData = (data: ITreatsFormResponse) => {
    const priorityMapping = {
      0: '0',
      1: '1',
      2: '2',
    };

    return {
      ...data,
      calm: priorityMapping[data.calm],
      relaxed: priorityMapping[data.relaxed],
      easy_going: priorityMapping[data.easy_going],
      friendly: priorityMapping[data.friendly],
      gentle: priorityMapping[data.gentle],
      obedient: priorityMapping[data.obedient],
    };
  };

  getNumberParts = (full_number: string) => {
    if (full_number.length <= 9) {
      return { number: full_number, rest: "351" };
    }

    const number = full_number.slice(-9);
    const code = full_number.slice(0, -9);

    return { number, code };
  };

  transformMedicalData = (data: IMedicalForm) => {
    const { code } = this.getNumberParts(data.hospital_number);
    const last_health_check_up = data.last_health_check_up === null ? "" : moment(data.last_health_check_up, "YYYY-MM-DD").format(
      "DD/MM/YYYY"
    );
    const microchip = data.microchip === null ? "" : data.microchip;
    const cheap = !!data.microchip;
    return { ...data, country_code: code, last_health_check_up, microchip, cheap };
  };

  updatePetListData = (
    data: IMedicalForm | ITreatsFormResponse,
    flow: "medical" | "treats",
    isNew: boolean = false
  ) => {
    let body: IMedicalForm | ITreatsForm;

    if (flow === "medical") {
      body = this.transformMedicalData(data as IMedicalForm);
    } else {
      body = this.transformData(data as ITreatsFormResponse);
    }

    const updatedPets = this.state.petsList.map((pet) => {
      if (pet.id === this.state.selectedPet?.id) {
        return { ...pet, [flow]: body };
      }
      return pet;
    });

    this.setState({ petsList: updatedPets }, () => {
      if (flow === "medical") {
        this.setState({ 
          medical: body as IMedicalForm, 
          ...(isNew && { isEditTreats: true, currentTab: 2 }) as { isEditTreats: boolean, currentTab: number }
        });
      } else {
        this.setState({ treats: body as ITreatsForm });
      }
    });
  };

  apiCall = async (data: APIModal) => {
    const { contentType, method, endPoint, body, token, formData } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    formData &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPetsList = async () => {
    this.apiPetListCallId = await this.apiCall({
      contentType: configJSON.httpContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.petListApiEndPoint,
      token: this.state.token,
    });
  };

  manageMedicalForm = async (data: IMedicalForm, formData: FormData) => {
    if (data.id) {
      this.apiUpdateMedicalCallId = await this.apiCall({
        method: configJSON.httpPatchMethod,
        endPoint: `${configJSON.updatePetMedicalsEndPoint}/${data.id}`,
        token: this.state.token,
        formData: formData,
      });
    } else {
      this.apiCreateMedicalsCallId = await this.apiCall({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.createPetMedicalsEndPoint,
        token: this.state.token,
        formData: formData,
      });
    }
  }

  sendMedicalForm = async (data: IMedicalForm) => {
    if (!this.state.selectedPet) {
      return;
    }

    this.setState({ isLoadingMedicalForm: true });
    const excludeFields = ["cheap", "country_code"];
    const formData = new FormData();
    formData.append(
      "pet_medical[animal_type_id]",
      `${this.state.selectedPet.id}`
    );

    (Object.keys(data) as (keyof IMedicalForm)[]).forEach((key) => {
      if (excludeFields.includes(key)) {
        return;
      }

      const value = data[key];
      if (value !== undefined && value !== null) {
        if (typeof value === "boolean") {
          formData.append(`pet_medical[${key}]`, value ? "true" : "false");
        } else {
          formData.append(`pet_medical[${key}]`, value as string | Blob);
        }
      }
    });

    this.manageMedicalForm(data, formData);
  };

  sendTreatsForm = async (data: ITreatsForm) => {
    if (this.state.selectedPet) {
      this.setState({ isLoadingTreatsForm: true, treats: data });
      const body = {
        traits: {
          ...data,
          animal_type_id: this.state.selectedPet.id,
        },
      };

      if (data.id) {
        this.apiUpdateTreatsCallId = await this.apiCall({
          method: configJSON.httpPatchMethod,
          contentType: configJSON.httpContentType,
          endPoint: `${configJSON.updatePetTreatsEndPoint}/${data.id}`,
          token: this.state.token,
          body: body,
        });
      } else {
        this.apiCreateTreatsCallId = await this.apiCall({
          method: configJSON.httpPostMethod,
          contentType: configJSON.httpContentType,
          endPoint: configJSON.createPetTreatsEndPoint,
          token: this.state.token,
          body: body,
        });
      }
    }
  };

  doEmailLogin = () => {
    const token = localStorage.getItem("login_token") || '';
    const userId = localStorage.getItem("login_user_id") || '';
    if(token && userId) {
      this.setState({ token, userId, isLoadingApp: false });
    } else {
      this.goToLogin();
    }
  };

  handleCountryNameChange = (country: string) => {
    const cityList = require("countries-cities")
      .getCities(country)
      .map((city: string) => ({
        label: city,
        value: city,
      }));
    this.setState({
      cities: cityList,
      isoCountryName: getCountryCode(country || "Portugal"),
    });
  };

  getSortedSessionsList = () => {
    const currentUUID = localStorage.getItem('device_uuid') || '';
    const list = [...this.state.sessions];
    const index = list.findIndex(item => item.device_id === currentUUID);
    if (index > -1) {
      list.unshift(list.splice(index, 1)[0]);
    }

    return list;
  }

  getCountryListData = () => {
    const countriesWithCities: string[] =
      require("countries-cities").getCountries();
    const countryList: ICountryModel[] = countriesWithCities
      .filter((country: string) => {
        const cities: string[] = require("countries-cities").getCities(country);
        return cities && cities.length > 0;
      })
      .map((country: string) => ({
        label: country,
        value: country,
      }));
    this.setState({ countries: countryList });
  };

  appendFormData = (formData: FormData, key: keyof IPet, value: IPet[keyof IPet]) => {
    if (value !== undefined && value !== null) {
      if (typeof value === "boolean") {
        formData.append(`pet[${key}]`, value ? "true" : "false");
      } else {
        formData.append(`pet[${key}]`, value as string | Blob);
      }
    }
  };

  getAppointmentsList = async (view: (typeof Views)[Keys], date: Date) => {
    const params: AppointmentParams = {};

    if(view === Views.WEEK) {
      params.filter_type = 'week';
      params.start_date = getAppointmentParams(date, 'week').start;
      params.end_date = getAppointmentParams(date, 'week').end;
    } else {
      params.filter_type = 'month';
      params.start_date = getAppointmentParams(date, 'month').start;
      params.end_date = getAppointmentParams(date, 'month').end;
    }

    const queryString = new URLSearchParams(params as Record<string, string>).toString();

    this.apiGetAppointmentsCallId = await this.apiCall({
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getAppointmentsEndpoint}${queryString}`,
      token: this.state.token,
    });
  }

  updatePetGeneral = async (data: IPet) => {
    if (!this.state.selectedPet || !this.state.selectedPet.name) {
      return;
    }
    const formData = new FormData();
    formData.append(
      "pet[account_id]",
      `${this.state.userId}`
    );

    formData.append(
      "pet[name]",
      `${this.state.selectedPet.name}`
    );

    let excludeFields = ["id", "created_at", "updated_at", "medical", "treats", "pet_photo", "photo_blob"];
    if(data.photo_blob) {
      formData.append("pet[pet_photo]", data.photo_blob)
    }

    (Object.keys(data) as (keyof IPet)[]).forEach((key) => {
      if (!excludeFields.includes(key)) {
        this.appendFormData(formData, key, data[key]);
      }
    });

    if(data.id) {
      this.setState({isLoadingPhoto: true});
      this.apiUpdateGeneralCallId = await this.apiCall({
        method: configJSON.httpPutMethod,
        endPoint: `${configJSON.updatePetGeneralEndPoint}/${data.id}`,
        token: this.state.token,
        formData: formData,
      });
    } else {
      this.setState({ isLoadingCreatePet: true })
      this.apiCreatePetCallId = await this.apiCall({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.updatePetGeneralEndPoint,
        token: this.state.token,
        formData
      })
    }
  }

  changeCurrentPet = (pet: IPet) => {
    this.setState({
      selectedPet: pet,
      medical: pet.medical || defaultMedical,
      treats: pet.treats || defaultTreats,
      isEditMedical: false,
      isEditTreats: false,
      isEditGeneral: false,
      currentTab: 0
    });
  };

  getPhoneErrors = <T,>(
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    field: keyof T
  ) => {
    if (Boolean(errors[field]) && touched[field]) {
      return {
        style: "2px solid #EF4444",
        childrens: touched[field] && errors[field]?.toString(),
      };
    } else {
      return {
        style: "1px solid #B7B7B7",
      };
    }
  };

  getDotStyle = (value: string | undefined, dotNumber: number) => {
    const notActiveStyles = {
      width: "11px",
      height: "11px",
      background: "#E7E7E7",
      borderRadius: "50%",
      transition: "background-color 0.3s, width 0.3s, height 0.3s",
    };

    const activeStyle = {
      width: "20px",
      height: "20px",
      background: "#6F05EA",
      borderRadius: "50%",
      transition: "background-color 0.3s, width 0.3s, height 0.3s",
    };

    if (value !== undefined) {
      if (Number(value) === dotNumber) {
        return activeStyle;
      }

      return { ...notActiveStyles, background: "#E2CDFB" };
    } else {
      return notActiveStyles;
    }
  };

  getLabelStyle = (isEdit: boolean) => {
    return isEdit ? styles.filledLabel : styles.secondaryLabel
  }

  getInputProps = <T extends object>(
    field: keyof T,
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    values: T,
    isEdit: boolean,
  ) => {
    return {
      id: field,
      name: field,
      fullWidth: true,
      error: Boolean(errors[field]) && touched[field],
      helperText: (touched[field] && errors[field])?.toString(),
      labelStyle: isEdit ? styles.filledLabel : styles.secondaryLabel
    };
  };

  setTraitField = (field: string, value: number) => {
    this.setState((prevState) => ({
      treats: {
        ...prevState.treats,
        [field]: value,
      },
    }));
  };

  getLabel = (string: string) => {
    const key = string.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
    return configJSON[key]
  }

  getGenderIconPath = (gender: string) => {
    if (gender === "Female") {
      return female;
    }

    return male;
  };

  formatDateAsYearsOld = (dateString: string) => {
    const date = moment(dateString, "DD/MM/YYYY").toDate();
    const now = new Date();
    let yearsOld = now.getFullYear() - date.getFullYear();

    const hasHadBirthdayThisYear =
      now.getMonth() > date.getMonth() ||
      (now.getMonth() === date.getMonth() && now.getDate() >= date.getDate());

    if (!hasHadBirthdayThisYear) {
      yearsOld--;
    }

    const plural = yearsOld > 1 ? "years" : "year";
    return `${yearsOld} ${plural} old`;
  };

  cancelEditForm = (field: string, resetCallback: () => void) => {
    this.setState({ [field as "isEditMedical"]: false });
    resetCallback();
  };

  updatePetField = (field: keyof IPet, value: IPet[keyof IPet]) => {
    if(this.state.selectedPet) {
      this.setState(prevState => ({
        selectedPet: prevState.selectedPet ?  {
          ...prevState.selectedPet,
          [field]: value
        } : null
      }))
    }
  };

  handleOnlyLettersAndSpaces = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, val: string) => void,
    field: string
  ) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFieldValue(field, filteredValue);
  };

  getDeviceImage = (os: string) => {
    switch (os) {
      case 'Windows':
      case 'Linux':
        return windowsImage;
      case 'MacOS':
        return macImage;
      case 'Android':
      case 'iOS':
        return phoneIcon;
    }
  }

  getSpiecesList = (category: string) => {
    if(category === 'rabbit/rodent') {
      return [
        { label: "Rabbit", value: "Rabbit" },
        { label: "Genui Pig", value: "Genui Pig" },
        { label: "Hamster", value: "Hamster" },
        { label: "Rat", value: "Rat" },
        { label: "Gerbil", value: "Gerbil" },
      ]
    } else {
      return [
        { label: "Dragon", value: "Dragon" },
        { label: "Snake", value: "Snake" },
        { label: "Gecko", value: "Gecko" },
        { label: "Turtles", value: "Turtles" },
      ]
    }
  }

  handleDeletePet = async () => {
    if(this.state.selectedPet) {
      this.apiDeletePetCallId = await this.apiCall({
        method: configJSON.httpDeleteMethod,
        endPoint: `${configJSON.deletePetEndPoint}/${this.state.selectedPet.id}`,
        token: this.state.token,
      })
    }
  }

  handleRemovePhoto = async () => {
    if(this.state.selectedPet) {
      this.setState({isLoadingPhoto: true})
      this.apiRemovePhoto = await this.apiCall({
        method: configJSON.httpDeleteMethod,
        endPoint: `${configJSON.removePhoto}${this.state.selectedPet.id}`,
        token: this.state.token,
      })
    }
  }

  updateUserAvatar = async (image: Blob) => {
    const formData = new FormData();
    formData.append("data[vendor_image]", image);
    this.apiUpdateProfileImageCallId = await this.apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.updateAvatar}${this.state.personal.id}`,
      token: this.state.token,
      formData: formData
    })
  }

  updateUserProfile = async (data: IPersonalInfo) => {
    const body: Record<string, any> = {
      first_name: data.first_name,
      last_name: data.last_name,
      full_phone_number: data.full_phone_number,
      phone_number: data.phone_number,
      coutry_code: data.country_code,
      date_of_birth: data.date_of_birth,
      nationality: data.nationality,
      city: data.city,
      country: data.country,
      language: data.language,
      zipcode: data.zipcode,
      address: data.address
    }

    const formData = new FormData();
    (Object.keys(data)).forEach((key) => {
      const value = body[key];
      if (value !== undefined && value !== null) {
        if (typeof value === "boolean") {
          formData.append(`data[${key}]`, value ? "true" : "false");
        } else {
          formData.append(`data[${key}]`, value as string | Blob);
        }
      }
    });
    
    this.apiUpdateAccountCallId = await this.apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.getAccountData}${data.id}`,
      token: this.state.token,
      formData: formData
    })
  }

  handleCustomerHeaderSelect = (selectedValue:string) => {
    this.setState({currentMenuItem:selectedValue})
  }

  handleSaveActiveMenu = (idx: string) => {
    this.setState({ currentMenuItem: idx.toString() })
    localStorage.setItem('selectedValueCustomer', idx);
  }

  // CARD
  changeAddCardModalDefault = (defaultStatus: boolean) => {
    this.setState({
      setDefaultAddCard: defaultStatus
    })
  }

  openAddCardModal = () => {
    this.setState({
      addCardModal: true,
      addCardModalError: ""
    })
  }
  closeAddCardModal = () => {
    this.setState({
      addCardModal: false,
      addCardModalError: ""
    })
  }

  setAddCardModalError = (errorMessage: string) => {
    this.setState({
      addCardModalError: errorMessage
    })
  }
  clearAddCardModalError = () => {
    this.setState({
      addCardModalError: ""
    })
  }

  getCardList = async () => {
    this.setState({ paymentCardListLoading: true })
    this.getCardListApiCallId = await this.apiCall({
      contentType: configJSON.API_CONTENT_TYPE.APPLICATION_JSON,
      method: configJSON.API_METHOD.GET,
      endPoint: configJSON.ENDPOINTS.GET_PAYMENT_CARD_LIST,
      token: this.state.token,
    });
  }

  onSubmitAddCardModal = async (card_token: string) => {
    this.setState({ addCardLoading: true })
    const body = { card_token }
    this.addCardApiCallId = await this.apiCall({
      contentType: configJSON.API_CONTENT_TYPE.APPLICATION_JSON,
      method: configJSON.API_METHOD.POST,
      endPoint: configJSON.ENDPOINTS.CREATE_PAYMENT_CARD,
      token: this.state.token,
      body
    })
  }

  handleSetAsDefault = async (payment_method_id: string) => {
    this.setState({ setDefaultCardLoading: true })
    const body = { payment_method_id }
    this.setDefaultCardApiCallId = await this.apiCall({
      contentType: configJSON.API_CONTENT_TYPE.APPLICATION_JSON,
      method: configJSON.API_METHOD.PUT,
      endPoint: configJSON.ENDPOINTS.SET_DEFAULT_CARD,
      token: this.state.token,
      body
    })
  }

  handleDeleteCard = async (payment_method_id: string) => {
    this.setState({ deleteCardLoading: true })
    const body = { payment_method_id }
    this.deleteCardApiCallId = await this.apiCall({
      contentType: configJSON.API_CONTENT_TYPE.APPLICATION_JSON,
      method: configJSON.API_METHOD.DELETE,
      endPoint: configJSON.ENDPOINTS.DELETE_CARD,
      token: this.state.token,
      body
    })
  }

  getCardListApiResp = (responseJson: IPaymentCardResponse) => {
    this.setState({ paymentCardListLoading: false })
    if (responseJson && responseJson.payment_methods && responseJson.payment_methods.length) {
      this.setState({
        paymentCardList: responseJson.payment_methods,
      })
    } else {
      this.setState({
        paymentCardList: []
      })
    }
  }

  addCardApiResp = (responseJson: ICreateAddCardResponse) => {
    this.setState({ addCardLoading: false })
    if (responseJson && 'id' in responseJson) {
      if (this.state.setDefaultAddCard) {
        this.handleSetAsDefault(responseJson.id)
      } else {
        this.getCardList()
        this.closeAddCardModal()
      }
    } else if ('error' in responseJson) {
      this.setAddCardModalError(responseJson.error)
    } else if ('message' in responseJson) {
      this.setAddCardModalError(responseJson.message)
    } else if (responseJson?.errors && responseJson?.errors.length) {
      this.setAddCardModalError(responseJson.errors[0]?.stripe)
    }
  }

  setDefaultCardApiResp = (responseJson: ICardMessage) => {
    this.setState({ setDefaultCardLoading: false })
    if (responseJson && responseJson.message && responseJson.message.includes("updated successfully")) {
      this.getCardList()
      this.closeAddCardModal()
    }
  }

  deleteCardApiResp = (responseJson: ICardMessage) => {
    this.setState({ deleteCardLoading: false })
    if (responseJson && responseJson.message && responseJson.message.includes("delete successfully")) {
      this.getCardList()
    }
  }

  // Customizable Area End
}
