export const enVendorSignUp = {
    //STEP1
    "Register your business":"Register your business",
    "Select your type of register":"Select your type of register",
    //STEP2
    "Fill in your business info":"Fill in your business info",
        //FIELD
        "Full Name":"Full Name",
        "Company Name":"Company Name",
        "Tax number":"Tax number",
        "Individual Tax Number":"Individual Tax Number",
        "Address":"Address",
        "Zip Code":"Zip Code",
        "Country":"Country",
        "Your city":"Your city",
        "Phone":"Phone",
        //PLACEHOLDER
        "Your name":"Your name",
        "Your address":"Your address",
        "Company name":"Company name",
        "Your zip code":"Your zip code",
        "Your country":"Your country",
        //ERROR
        "*Please Enter Name":"*Please Enter Name",
        "*Please Enter Company name":"*Please Enter Company name",
        "*Please Enter Tax Number":"*Please Enter Tax Number",
        "*Please select Address.":"*Please select Address.",
        "*Please Enter Zip Code":"*Please Enter Zip Code",
        "*Please Select Country":"*Please Select Country",
        "*Please Select city":"*Please Select city",
        "*Your Enter Phone":"*Your Enter Phone",
        "*Tax number must be a number":"*Tax number must be a number",
        "User Name should only contain letters":"User Name should only contain letters",
    //STEP3
    "Set your account":"Set your account",
        //FIELD
        "Email":"Email",
        "Password":"Password",
        "Confirm Password":"Confirm Password",
        //PLACEHOLDER
        "Your email":"Your email",
        //ERROR
        "*Please enter email":"*Please enter email",
        "*Please enter password.":"*Please enter password.",
        "*Please enter confirm password.":"*Please enter confirm password.",
        "Invalid email address":"Invalid email address",
        "Password should include at least 8 characters, one number, and one letter.":"Password should include at least 8 characters, one number, and one letter.",
        "*Passwords does not match.":"*Passwords does not match.",
    //STEP4
    "Set your service":"Set your service",
        //SERVICE NAME
        "Pet hotel":"Pet hotel",
        "Dog care":"Dog care",
        "Dog walking":"Dog walking",
        "Pet sitting":"Pet sitting",
        "Grooming":"Grooming",
        "Training":"Training",
    // STEP5
    "Thank you for trusting Petzai!":"Thank you for trusting Petzai!",
    "Your business registration is now pending approval.":"Your business registration is now pending approval.",
    "You will receive an email in the next 1 to 2 business days.":"You will receive an email in the next 1 to 2 business days.",
    "We also sent a copy of the filled form to":"We also sent a copy of the filled form to",
    "please make sure to validate if the information is correct.":"please make sure to validate if the information is correct.",
    //BUTTON
    "Next":"Next",
}


export const ptVendorSignUp = {
    //STEP1
    "Register your business":"Cadastre sua empresa",
    "Select your type of register":"Selecione seu tipo de cadastro",
    "Individual":"Individual",
    "Company":"Empresa",
    //STEP2
    "Fill in your business info":"Preencha as informações da sua empresa",
        //FIELD
        "Full Name":"Nome completo",
        "Company Name":"nome da empresa",
        "Tax number":"Número fiscal",
        "Individual Tax Number":"Número fiscal individual",
        "Address":"Endereço",
        "Zip Code":"CEP",
        "Country":"País",
        "Your city":"Sua cidade",
        "Phone":"Telefone",
        //PLACEHOLDER
        "Your name":"Seu nome",
        "Your address":"Seu endereço",
        "Company name":"Nome da empresa",
        "Your zip code":"Seu CEP",
        "Your country":"Seu país",
        //ERROR
        "*Please Enter Name":"*Por favor, insira o nome",
        "*Please Enter Company name":"*Insira o nome da empresa",
        "*Please Enter Tax Number":"*Insira o número fiscal",
        "*Please select Address.":"*Selecione Endereço.",
        "*Please Enter Zip Code":"*Insira o CEP",
        "*Please Select Country":"*Selecione o país",
        "*Please Select city":"*Selecione a cidade",
        "*Your Enter Phone":"*Seu telefone digitado",
        "*Tax number must be a number":"*O número fiscal deve ser um número",
        "User Name should only contain letters":"O nome de usuário deve conter apenas letras",
    //STEP3
    "Set your account":"Defina sua conta",
        //FIELD
        "Email":"E-mail",
        "Password":"Senha",
        "Confirm Password":"Confirme sua senha",
        //PLACEHOLDER
        "Your email":"Seu e-mail",
        //ERROR
        "*Please enter email":"*Insira o e-mail",
        "*Please enter password.":"*Por favor, insira a senha.",
        "*Please enter confirm password.":"*Por favor, insira a confirmação da senha.",
        "Invalid email address":"Endereço de e-mail inválido",
        "Password should include at least 8 characters, one number, and one letter.":"A senha deve incluir pelo menos 8 caracteres, um número e uma letra.",
        "*Passwords does not match.":"*As senhas não coincidem.",
    //STEP4
    "Set your service":"Defina seu serviço",
        //SERVICE NAME
        "Pet hotel":"Hotel para animais de estimação",
        "Dog care":"Cuidados com cães",
        "Dog walking":"Passear com cachorro",
        "Pet sitting":"Cuidar de animais de estimação",
        "Grooming":"Asseio",
        "Training":"Treinamento",
    // STEP5
    "Thank you for trusting Petzai!":"Obrigado por confiar na Petzai!",
    "Your business registration is now pending approval.":"O registro da sua empresa está pendente de aprovação.",
    "You will receive an email in the next 1 to 2 business days.":"Você receberá um e-mail nos próximos 1 a 2 dias úteis.",
    "We also sent a copy of the filled form to":"Também enviamos uma cópia do formulário preenchido para",
    "please make sure to validate if the information is correct.":"certifique-se de validar se as informações estão corretas.",
    //BUTTON
    "Next":"Próximo",
}